import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { Select } from "antd";
import LogInPopup from "../AuthScreen/LogInPopup";
import Navbar from "../../component/common/NavbarProfile";
import { getProducts } from "../../handlers/common/api";
import Defaultimage from "../../assets/images/CricketKit.jpg";

const AccessoriesPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [venueData, setVenueData] = useState(null);
  const [serviceItems, setServiceItems] = useState([]);
  const [productItems, setProductItems] = useState([]);
  const [rentalItems, setRentalItems] = useState([]);
  const [selectedServiceOptions, setSelectedServiceOptions] = useState();
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve venueData from localStorage
    const token = localStorage.getItem("venueData");
    if (token) {
      setVenueData(JSON.parse(token));
    }

    const items = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(items);
  }, []);

  // Fetch product details
  const fetchProductDetails = async () => {
    try {
      const options = {
        method: "POST",
        body: JSON.stringify({ item_group: "Products" }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(`${getProducts}`, options);
      if (!response.ok) {
        throw new Error("Failed to fetch venue details");
      }
      const data = await response.json();

      const arr = [];
      const optionList = data.data;
      for (let i = 0; i < optionList?.length; i++) {
        if (optionList[i].price !== 0) { // Filter out products with price = 0
          arr.push({
            name: optionList[i].name,
            value: optionList[i].name,
            label: optionList[i].name,
            price: optionList[i].price,
            image: optionList[i].image,
            item_category: "Products",
          });
        }
      }
      setProductItems(arr);
    } catch (error) {
      console.error("Error fetching venue details:", error);
    }
  };

  // Fetch service details
  const fetchServicesDetails = async () => {
    try {
      const options = {
        method: "POST",
        body: JSON.stringify({ item_group: "Services" }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(`${getProducts}`, options);
      if (!response.ok) {
        throw new Error("Failed to fetch venue details");
      }
      const data = await response.json();

      const arr = [];
      const optionList = data.data;
      for (let i = 0; i < optionList?.length; i++) {
        if (optionList[i].price !== 0) {
          arr.push({
            value: optionList[i].name,
            label: optionList[i].name + " - " + optionList[i].price + "/hr",
            image: optionList[i].image,
            price: optionList[i].price,
          });
        }
      }
      const sortedArr = arr.slice().sort((a, b) => b.price - a.price);
      setServiceItems(sortedArr);
      setSelectedServiceOptions(sortedArr[0]);
      let selecteditem = sortedArr[0];
      let payload = {
        item_code: sortedArr[0].value,
        rate: selecteditem.price,
      };
      localStorage.setItem("Product_Service", JSON.stringify(payload));
    } catch (error) {
      console.error("Error fetching venue details:", error);
    }
  };

  // Fetch rental details
  const fetchRentalDetails = async () => {
    try {
      const options = {
        method: "POST",
        body: JSON.stringify({ item_group: "Rental" }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(`${getProducts}`, options);
      if (!response.ok) {
        throw new Error("Failed to fetch venue details");
      }
      const data = await response.json();

      const arr = [];
      const optionList = data.data;
      for (let i = 0; i < optionList?.length; i++) {
        if (optionList[i].price !== 0) {
          arr.push({
            name: optionList[i].name,
            value: optionList[i].name,
            label: optionList[i].name,
            price: optionList[i].price,
            image: optionList[i].image,
            item_category: "Rental",
          });
        }
      }
      setRentalItems(arr);
    } catch (error) {
      console.error("Error fetching venue details:", error);
    }
  };

  useEffect(() => {
    fetchProductDetails();
    fetchRentalDetails();
    fetchServicesDetails();
  }, []);

  // Component for individual accessory card
const AccessoryCard = React.memo(({ name, price, image, item_category }) => {
  const [cartQuantity, setCartQuantity] = useState(0);

  useEffect(() => {
    // Retrieve cart items from localStorage
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    
    // Find the item in cart
    const cartItem = storedCartItems.find(item => item.name === name);
    
    // Set the cart quantity
    if (cartItem) {
      setCartQuantity(cartItem.quantity);
    } else {
      setCartQuantity(0);
    }
  }, [name]);

  const addItemToCart = () => {
    const updatedCartItems = [...cartItems];
    const existingItemIndex = updatedCartItems.findIndex(
      (item) => item.name === name
    );
    if (existingItemIndex !== -1) {
      updatedCartItems[existingItemIndex].quantity += 1;
    } else {
      updatedCartItems.push({
        name,
        price,
        image,
        item_category,
        quantity: 1,
      });
    }

    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    setCartQuantity(cartQuantity + 1);
  };

  const removeItemFromCart = () => {
    if (cartQuantity > 0) {
      const updatedCartItems = [...cartItems];
      const existingItemIndex = updatedCartItems.findIndex(
        (item) => item.name === name
      );
      if (existingItemIndex !== -1) {
        updatedCartItems[existingItemIndex].quantity -= 1;
        setCartItems(updatedCartItems);
        localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
        setCartQuantity(cartQuantity - 1);
      }
    }
  };

    return (
      <div
      className="rounded border m-2 p-2 mb-4 md:mr-10 flex flex-col justify-between"
      style={{ width: "100%", maxWidth: "219px", height: "auto" }}
    >
      {/* Top part with just the image */}
      {image.length > 10 ? (
        <Link to={`/item-detail/${item_category}/${name}`}>
          <div className="relative">
            <img
              key={image}
              className="w-full h-36 object-cover"
              src={image}
              alt={name}
              onError={(e) => {
                e.target.src = Defaultimage;
              }}
            />
          </div>
        </Link>
      ) : (
        <div className="relative">
          <img
            key={image}
            className="w-full h-36 object-cover"
            src={image}
            alt={name}
            onError={(e) => {
              e.target.src = Defaultimage;
            }}
          />
        </div>
      )}
      {/* Bottom part with name, price, quantity and add to cart button */}
      <div className="px-2 py-1">
        {/* Name */}
        <h2 className="font-bold text-sm mb-1">{name}</h2>
        {/* Price */}
        <p className="text-gray-700 text-xs">$ {price}/hour</p>
      </div>

      {/* Quantity control and Add to Cart button */}
      <div className="flex justify-between items-center">
        {/* Quantity controls */}
        <div className="flex items-center">
          <button
            className="text-gray-700 font-bold py-1 px-2 rounded border border-gray-600 mr-2"
            onClick={removeItemFromCart}
          >
            -
          </button>
          <span className="text-gray-700">{cartQuantity}</span>
          <button
            className="text-gray-700 font-bold py-1 px-2 rounded border border-gray-600 ml-2"
            onClick={addItemToCart}
          >
            +
          </button>
        </div>
        {/* Add to Cart button */}
        <div className="flex justify-center">
          <button
            className="bg-red-200 hover:bg-red-300 text-red-600 font-bold py-1 px-2 rounded border border-red-600 mr-2"
            style={{ width: "75px" }}
            onClick={addItemToCart}
          >
            ADD
          </button>
        </div>
      </div>
    </div>
    );
  });

  const handleProceedToCheckout = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setShowLoginPopup(true);
    } else {
      navigate("/viewcart");
    }
  };

  const closeLoginPopup = () => {
    setShowLoginPopup(false);
  };

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 lg:ml-10">
        <header className="mb-4 relative">
          <div className="flex justify-between">
            <h1 className="text-3xl mt-2 font-bold text-start">
              {venueData?.data?.name}
            </h1>
            <div className="mt-2 mb-8 justify-end">
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                onClick={handleProceedToCheckout}
              >
                Proceed to Checkout
              </button>
            </div>
          </div>
          {false && (
            <div style={{ position: "relative" }}>
              <AddShoppingCartIcon
                style={{ width: "40px", height: "40px", cursor: "pointer" }}
              />
              {cartItems && (
                <div className="absolute top-0 right-0 mt-10 mr-7 text-white bg-black rounded-full h-6 w-6 flex items-center justify-center">
                  {cartItems.length}
                </div>
              )}
            </div>
          )}
        </header>
        {/* Section for product selection */}
        <div className="mt-8">
          <Select
            style={{ width: "auto", minWidth: "150px" }}
            placeholder="Product"
            defaultValue={serviceItems[0]}
            value={selectedServiceOptions}
            options={serviceItems}
            onChange={(value) => {
              setSelectedServiceOptions(value);
              let selecteditem = serviceItems.find(
                (item) => item.value === value
              );
              let payload = {
                item_code: value,
                rate: selecteditem.price,
              };
              localStorage.setItem("Product_Service", JSON.stringify(payload));
            }}
          />
        </div>
        {/* Section for accessories */}
        <div className="mt-8">
          <h1 className="font-bold text-2xl mb-4">Add Accessories</h1>
          <div className="flex flex-wrap justify-center sm:justify-start lg:justify-start mb-10">
            {rentalItems.map((item, index) => (
              <AccessoryCard key={index} {...item} />
            ))}
          </div>
        </div>
        {/* Proceed to checkout button */}
        <div className="mt-8 mb-8 flex justify-end">
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleProceedToCheckout}
          >
            Proceed to Checkout
          </button>
        </div>
      </div>
      {showLoginPopup && <LogInPopup closePopup={closeLoginPopup} />}
    </>
  );
};

export default AccessoriesPage;
