import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Divider, Row } from "antd";
import Navbar from "../../component/common/NavbarProfile";
import image from "../../assets/images/cardDetailsImage.png"; //set real venue image
import { venueListingPage } from "../../handlers/common/api";
import batIcon from "../../assets/images/bat-icon.png";
import Contact from "../../assets/images/iconamoon_profile-thin.png";
import Email from "../../assets/images/iconamoon_email-thin.png";
import Phone from "../../assets/images/ph_phone-thin.png";
import defaultPitch from "../../assets/images/pitch default.jpeg";

const VenueDetailsPage = () => {
  const { name } = useParams();
  const decodedName = decodeURIComponent(name.replace(/\+/g, " "));
  const formattedName = decodedName.replace(/%20/g, " ");
  const [venue, setVenue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showUnderline, setShowUnderline] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVenueDetails = async () => {
      try {
        const response = await fetch(`${venueListingPage}/${formattedName}`);
        if (!response.ok) {
          throw new Error("Failed to fetch venue details");
        }
        const data = await response.json();
        setVenue(data);
        // setVenueComponents(data.venue_components)
        setShowUnderline(true);
      } catch (error) {
        console.error("Error fetching venue details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVenueDetails();
  }, [formattedName]);

  const handleBookNow = () => {
    if (venue) {
      // Save venue data to localStorage before navigating
      localStorage.setItem("venueData", JSON.stringify(venue));
      navigate(
        `/venue-listing-page/venue/${encodeURIComponent(
          venue.data.name
        )}/booking-page`
      );
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!venue) {
    return <div>Error: venue not found</div>;
  }

  const venueMoreInformationText =
    venue.data && venue.data.venue_more_information
      ? venue.data.venue_more_information.replace(/<\/?[^>]+(>|$)/g, "")
      : "";

  const insertLineBreaks = (text, breakAfter) => {
    let result = "";
    for (let i = 0; i < text.length; i++) {
      result += text[i];
      if ((i + 1) % breakAfter === 0) {
        result += "<br>";
      }
    }
    return result;
  };

  const extractTextWithSerialNumbers = (htmlString) => {
    if (!htmlString) return ""; // Check if htmlString is undefined or null
    const listItems = htmlString.match(/<li[^>]*>(.*?)<\/li>/g);
    if (!listItems) return ""; // Return empty string if no list items found
    const linesWithSerialNumbers = listItems.map((item, index) => {
      const text = item.replace(/<\/?[^>]+(>|$)/g, "");
      return `${index + 1}. ${text}`;
    });
    return linesWithSerialNumbers.join("\n");
  };

  const venueDetailsTextWithSerialNumbers = extractTextWithSerialNumbers(
    venue.data.venue_details
  );

  return (
    <>
      <Navbar />
      <div>
        <header>
          <div className="flex justify-between items-center p-8 header-section">
            <h1 className="text-3xl font-bold text-start ml-20 mb-1 mt-4 relative venueDetailsHeading">
              {venue.data.name}
              <br />
              <h6 className="relative text-sm">
                {venue.data.address}
                {/* {showUnderline && (
                  <div
                    className="absolute bg-black"
                    style={{
                      width: "600px",
                      height: "1px",
                      bottom: "-5px",
                      left: "0",
                    }}
                  ></div>
                )} */}
              </h6>
            </h1>
            <button
              onClick={handleBookNow}
              className="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-20 bookingButton"
            >
              Book Now
            </button>
          </div>
        </header>

        <div className="container mx-auto  flex flex-col justify-center">
          <>
            <img
              src={`${venue.data.base_url}${venue.data.venue_image}`}
              alt="Venue"
              className="w-full h-auto mx-auto mb-5"
              style={{ maxHeight: "400px", maxWidth: "1380px" }}
              onError={(e) => {
                e.target.src = defaultPitch;
              }}
            />
            <div className="">
              <h1 className="font-bold">{venue.data.name}</h1>
              <p>
                14 football grounds · 7 badminton court · 2 swimming pools · 5
                table tennis court
              </p>
              <hr className="mr-14"></hr>
            </div>
          </>
        </div>
        <div className="mt-8 ml-20 venue-info">
          <p>
            <p
              dangerouslySetInnerHTML={{
                __html: insertLineBreaks(venueMoreInformationText, 150),
              }}
              className="venue-details"
            />
          </p>
          <Row justify="start">
          {venue.data.venue_components.map((component) => (
            <Col key={component.name} xs={{ span: 12 }} sm={{ span: 6 }} className="p-4 venue-cards">
              <div className="flex">
                <div>
                  <img src={batIcon} className="mr-2" />
                </div>
                <div>
                  <p className="font-bold venue-available-for">{component.component}</p>
                  <p className="available-venue-spec">{component.description}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        </div>
        <div className="flex justify-between ml-20 mt-12 mb-5 venue-contact">
          <div>
            <p className="mb-4">For Details Please Contact:</p>
            <div className="flex">
              <div>
                <img src={Contact} className="mr-2 mb-4" />
              </div>
              <div>
                <p>{venue.data.person_name}</p>
              </div>
            </div>
            <div className="flex">
              <div>
                <img src={Email} className="mr-2 mb-4" />
              </div>
              <div>
                <p>{venue.data.email}</p>
              </div>
            </div>
            <div className="flex">
              <div>
                <img src={Phone} className="mr-2 mb-4" />
              </div>
              <div>
                <p>+65{venue.data.tel}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VenueDetailsPage;
