import React, { useState, useEffect } from "react";
import { Switch } from "@mui/material";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { toast } from "react-toastify";
import {
  fetchUserDataApi,
  sendNotificationSettingsApi,
} from "../../handlers/myaccount/api";

const label = { inputProps: { "aria-label": "Switch demo" } };

function NotificationSettings() {
  const [emailNotification, setEmailNotification] = useState(false);
  const [smsNotification, setSmsNotification] = useState(false);
  const [optOutAll, setOptOutAll] = useState(false); // State for opting out of all notifications

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    try {
      const userData = await fetchUserDataApi(token);
      // Set initial state of emailNotification and smsNotification
      setEmailNotification(userData.data.notification_settings.email === 1);
      setSmsNotification(userData.data.notification_settings.sms === 1);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleEmailChange = () => {
    const newEmailNotification = !emailNotification;
    setEmailNotification(newEmailNotification);
    if (!optOutAll) {
      sendNotificationSettings({
        email_flag: newEmailNotification ? 1 : 0,
        sms_flag: smsNotification ? 1 : 0,
      });
    }
  };

  const handleSmsChange = () => {
    const newSmsNotification = !smsNotification;
    setSmsNotification(newSmsNotification);
    if (!optOutAll) {
      sendNotificationSettings({
        email_flag: emailNotification ? 1 : 0,
        sms_flag: newSmsNotification ? 1 : 0,
      });
    }
  };

  const handleOptOutAllChange = () => {
    const newOptOutAll = !optOutAll;
    setOptOutAll(newOptOutAll);
    if (newOptOutAll) {
      setEmailNotification(false);
      setSmsNotification(false);
      sendNotificationSettings({ email_flag: 0, sms_flag: 0 }); // Sending 0 for both email and SMS when opting out of all notifications
    }
  };

  const sendNotificationSettings = async (payload) => {
    const token = localStorage.getItem("token");
    try {
      const response = await sendNotificationSettingsApi(payload, token);
      toast.success("Notification settings updated successfully", {
        toastId: "notificationToast", // Set a specific toastId for this message
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div>
      <h2 className="font-bold text-2xl leading-6">
        Receive booking reminder via
      </h2>
      <div>
        <div className="mt-4 flex items-center">
          <span>SMS</span>
          <Switch
            {...label}
            checked={smsNotification}
            onChange={handleSmsChange}
            style={{ color: "red" }}
          />
        </div>
        <div className="mt-3">
          <span>Email</span>
          <Switch
            {...label}
            checked={emailNotification}
            onChange={handleEmailChange}
            style={{ color: "red" }}
          />
        </div>
        <div className="mt-3">
          <input
            type="checkbox"
            checked={optOutAll}
            onChange={handleOptOutAllChange}
            style={{
              accentColor: "red",
              outlineColor: "red",
            }}
          />

          <span className="ml-2">Opt out of all notifications</span>
        </div>
      </div>
    </div>
  );
}

export default NotificationSettings;
