import React, { useEffect, useState } from "react";
import { getBookingHistory } from "../../handlers/myaccount/api";
import Image from "../../assets/images/cardimage.png";

function Bookings() {
  const [activeTab, setActiveTab] = useState("upcoming");
  const [additionalData, setAdditionalData] = useState("");
  const [bookingHistory, setBookingHistory] = useState([]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    // Set additional data based on the active tab
    if (tab === "upcoming") {
      setAdditionalData("Additional data for upcoming");
    } else if (tab === "past") {
      setAdditionalData("Additional data for past");
    }
  };

  const handleGetBookingHistory = async () => {
    const token = localStorage.getItem("token");

    const res = await getBookingHistory(token);
    if (res.status === 200) {
      const arr = res.data.data.booking_details.map((booking) => ({
        name: booking.name,
        items: booking.items,
      }));
      setBookingHistory(arr);
    }
  };

  const formatTime = (timeString) => {
    const hour = parseInt(timeString.substr(0, 2), 10);
    const ampm = hour >= 12 ? "PM" : "AM";
    const hour12 = hour % 12 || 12; // Convert 0 to 12
    const formattedTime = `${hour12} ${ampm}`;
    return formattedTime;
  };

  useEffect(() => {
    handleGetBookingHistory();
  }, []);

  return (
    <div className="relative flex flex-col">
      {bookingHistory.map((bookingitem, index) => (
        <div key={index} className="order-box">
          {bookingitem.items.map((item, itemIndex) => (
            <div key={itemIndex} className="booking-item-box">
              {item.item_group === "Services" ? (
                <div className="booking-item">
                  <img
                    src={item.image || Image}
                    alt={item.name}
                    style={{ width: "100px", height: "100%" }}
                  />
                  <div className="ml-4">
                    <div className="text-black text-md">
                      {item.custom_venue.replace("-", "|")}
                      {" | "}
                      {item.custom_booking_for.split("-").pop().trim()}
                    </div>{" "}
                    <div className="text-gray-400">
                      Date: {item.custom_start_date.split(" ")[0]}
                    </div>
                    <div className="text-gray-400">
                      Time: {formatTime(item.custom_start_date.split(" ")[1])} -{" "}
                      {formatTime(item.custom_end_date.split(" ")[1])}
                    </div>
                    <div className="">$ {item.amount} SGD</div>
                  </div>
                </div>
              ) : (
                <div className="booking-item">
                  <img
                    src={item.image}
                    alt={item.item_name}
                    style={{ width: "100px", height: "100%" }}
                  />
                  <div className="ml-4 ">
                    <div className="text-black mb-2 text-md">{item.item_name}</div>

                    <div className="text-gray-400 mb-2  ">Quantity: {item.qty}</div>
                    <div className=""> $ {item.amount} SGD</div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Bookings;
