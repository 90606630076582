import React, { useEffect } from "react";
import Logo from "../../assets/images/company_logo_cricket.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBars } from "@fortawesome/free-solid-svg-icons";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { fetchUserDatasApi } from "../../handlers/myaccount/api";
import { toast } from "react-toastify";

function Navbar({ toggleSidebar }) {
  const [image, setImage] = useState();
  const navigate = useNavigate();
  let flag = false;

  const handleMyAccountNavigation = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate(`/my-account`);
    }
  };
  const handleHomeRedirection = () => {
    navigate(`/`);
  };

  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    console.log(token);
    if (token) {
      try {
        const userData = await fetchUserDatasApi(token);
        if (userData.email === "") {
          if (token) {
            toast.error("Your Session is expired please login again");
          }
          localStorage.removeItem("token");
        }
        setImage(userData.image);
      } catch (error) {
        console.error(error.message);
        return false;
      }
    }
  };

  const handleSignUp = () => {
    navigate("/sign-up");
  };
  const handleLogin = () => {
    navigate("/sign-in");
  };

  useEffect(() => {
    if (flag === false) {
      fetchUserData();
      flag = true;
    }
  }, []);

  return (
    <nav className="bg-[#f2f2f2] p-4 flex justify-between items-center w-auto navbar">
      {/* Left side icons */}
      <div className="flex items-center">
        <div
          className="bg-white rounded-full border border-gray-800 ml-12 flex"
          onClick={handleMyAccountNavigation}
          style={{ height: image ? "40px" : "36px" }}
        >
          <FontAwesomeIcon
            icon={faBars}
            size="lg"
            className="ml-2 mt-2"
            onClick={toggleSidebar}
          />
          {/* Using AccountCircleIcon directly as a component */}
          {image ? (
            <img
              className="ml-3 mt-1 mr-1 rounded-full"
              src={image}
              alt="pic"
              style={{ width: 29, height: 29 }} // adjust width and height as needed
            />
          ) : (
            <AccountCircleIcon fontSize="large" className="ml-3" />
          )}{" "}
        </div>
        <FontAwesomeIcon icon={faBell} size="lg" className="ml-3" />
      </div>
      <div className="flex items-center nav-links">
        <span className="mr-4 font-bold" onClick={handleLogin}>
          Log In
        </span>
        <span className="mr-4 font-bold" onClick={handleSignUp}>
          Sign Up
        </span>
        <img
          src={Logo}
          alt="Logo"
          onClick={handleHomeRedirection}
          className="h-12 mr-12 nav-logo"
        />
      </div>
    </nav>
  );
}

export default Navbar;
