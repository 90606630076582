import axios from "axios";
import { SearchActivity } from "../common/api";

export const getActivity = async (token) => {
    try {
      const response = await axios.get(
        SearchActivity,
      );
      return response;
    } catch (error) {
      throw new Error("Error getting Search Event:", error);
    }
  };