import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PitchIcon from "../../assets/images/Pitch-icon.png";
import IceBathIcon from "../../assets/images/ice_bath.png";
import TenisIcon from "../../assets/images/tenis.png";
import FootballIcon from "../../assets/images/Football.png";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FormControl, InputLabel, Input } from "@mui/material";
import LeftButton from "../../assets/images/left-button.png";
import RightButton from "../../assets/images/right-button.png";
import Navbar from "../../component/common/NavbarProfile";
import LoadingBackdrop from "../../component/common/Loader";
import {
  getAlreaduBookedPitches,
  getPitchData,
} from "../../handlers/product/api";

registerLocale("en-GB", enGB);

const PitchSelectionTable = () => {
  const [selectedPitch, setSelectedPitch] = useState(null);
  const [selectedPitchName, setSelectedPitchName] = useState(null);
  const [selectedPitchIcon, setSelectedPitchIcon] = useState(null);
  const [venueData, setVenueData] = useState(null);
  const [bookingOptions, setBookingOptions] = useState([]);
  const [selectedTab, setSelectedTab] = useState("day");
  const [selectOpen, setSelectOpen] = useState(false);
  const [pitche, setPitches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [bookedPitches, setbookedPitches] = useState([]);
  const [pitchWeekAvailability, setPitchWeekAvailability] = useState([]);

  const [popupInfo, setPopupInfo] = useState({
    pitch: null,
    time: null,
    date: null,
  });
  const [cartItems, setCartItems] = useState([]);
  const [startPitchIndex, setStartPitchIndex] = useState(0);
  const pitchesPerPage = 7; // number of pitch in slide bar
  const blocksPerPitch = 23; // number of time slot

  const navigate = useNavigate();

  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(currentDate);

  const endtoDate = new Date(currentDate);
  endtoDate.setDate(currentDate.getDate() + 6);

  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(endtoDate);

  function formatDate(date) {
    if (!date) {
      return ""; // Return an empty string if date is null or undefined
    }

    // Check if date is already a Date object
    if (!(date instanceof Date)) {
      // Attempt to create a Date object from the input
      date = new Date(date);
    }

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Return an empty string if date is invalid
    }

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${day} ${months[monthIndex]} ${year}`;
  }

  const prevWeek = () => {
    setLoading(true);
    const newStartDate = new Date(startDate);
    newStartDate.setDate(newStartDate.getDate() - 7);
    setStartDate(newStartDate);
    const newEndDate = new Date(endDate);
    newEndDate.setDate(newEndDate.getDate() - 7);
    setEndDate(newEndDate);
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 100);

    return () => clearTimeout(timeoutId);
  };

  const nextWeek = () => {
    setLoading(true);
    const newStartDate = new Date(startDate);
    newStartDate.setDate(newStartDate.getDate() + 7);
    setStartDate(newStartDate);
    const newEndDate = new Date(endDate);
    newEndDate.setDate(newEndDate.getDate() + 7);
    setEndDate(newEndDate);
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 100);

    return () => clearTimeout(timeoutId);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const clearDate = () => {
    setSelectedDate(null);
  };

  // const pitches = ["Pitch 1", "Pitch 2", "Pitch 3", "Pitch 4", "Pitch"];
  // const colors = ["#F88747", "#9547F8", "#FF7AFA", "#635954", "#476EF8"]; // Colors for each pitch
  // const coloring = ["#fee7db", "#eadbfe", "#ffc7fd", "#8c7e77", "#91a8fb"];

  // function dimColor(hexColor, percent = 15) {
  //   // Remove the '#' character from the hex color
  //   hexColor = hexColor.slice(1);

  //   // Parse the hexadecimal color into RGB components
  //   let red = parseInt(hexColor.substring(0, 2), 16);
  //   let green = parseInt(hexColor.substring(2, 4), 16);
  //   let blue = parseInt(hexColor.substring(4, 6), 16);

  //   // Calculate the dimming factor
  //   let factor = 1 - percent / 100;

  //   // Dim each RGB component
  //   red = Math.round(red * factor);
  //   green = Math.round(green * factor);
  //   blue = Math.round(blue * factor);

  //   // Convert the dimmed RGB values back to hexadecimal format
  //   red = red.toString(16).padStart(2, "0");
  //   green = green.toString(16).padStart(2, "0");
  //   blue = blue.toString(16).padStart(2, "0");

  //   // Combine the dimmed RGB values into a new hexadecimal color
  //   return `#${red}${green}${blue}`;
  // }

  const getTiming = (blockIndex) => {
    const startTime = (blockIndex % 24) + 1;
    const endTime = (startTime + 1) % 24 || 12; // For 24:00, display as 12:00 AM
    const startSuffix = startTime >= 12 ? "PM" : "AM";
    const endSuffix = endTime >= 12 ? "PM" : "AM";
    const formattedStartTime = startTime > 12 ? startTime - 12 : startTime;
    const formattedEndTime = endTime > 12 ? endTime - 12 : endTime;
    return `${formattedStartTime}:00 ${startSuffix}-${formattedEndTime}:00 ${endSuffix}`;
  };

  const fetchPitchData = async () => {
    try {
      const venuedata = JSON.parse(localStorage.getItem("venueData"));
      const res = await getPitchData(venuedata.data.name);
      const daysofweek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      const pitchWeekAvailability = {}; // Object to store availability for each day

      const arr = res.data.data.venue_availability_time;
      for (let i = 0; i < arr.length; i++) {
        const day = arr[i].day; // Get the day from the API response

        if (!pitchWeekAvailability[day]) {
          pitchWeekAvailability[day] = []; // Initialize array if not already present
        }

        // Push availability object to the array
        pitchWeekAvailability[day].push({
          start_time: arr[i].start_time,
          end_time: arr[i].end_time,
        });
      }
      console.log(pitchWeekAvailability);
      setPitchWeekAvailability(pitchWeekAvailability);
    } catch (error) {
      console.log(error);
    }
  };

  function checkIfTimeFitsInRangeoforDay(
    availableRangeStart,
    availableRangeEnd,
    timeToCheck
  ) {
    console.log(availableRangeStart, availableRangeEnd, timeToCheck);
    // Convert available range start and end to 24-hour format
    const availableStart = availableRangeStart.endsWith("PM")
      ? parseInt(availableRangeStart) + 12
      : parseInt(availableRangeStart);
    const availableEnd = availableRangeEnd.endsWith("PM")
      ? parseInt(availableRangeEnd) + 12
      : parseInt(availableRangeEnd);

    // Extract start and end times from the time range input
    const [startTime, endTime] = timeToCheck.split("-");

    // Convert start and end times to 24-hour format
    let [startHour, startMinute] = startTime.trim().split(":");
    let [endHour, endMinute] = endTime.trim().split(":");

    startHour = parseInt(startHour);
    endHour = parseInt(endHour);

    // Adjust hour for PM time
    if (startTime.includes("PM") && startHour !== 12) {
      startHour += 12;
    }
    // Adjust hour for PM time
    if (endTime.includes("PM") && endHour !== 12) {
      endHour += 12;
    }

    // Handle case where end time is before start time (e.g., "11:00 PM-12:00 AM")
    if (endHour < startHour) {
      endHour += 24; // Add 24 hours to the end time
    }

    // Check if the start and end times fall within the available range
    console.log(startHour, availableStart, endHour, availableEnd);
    return (
      startHour >= availableStart &&
      (endHour <= availableEnd ||
        endHour <= availableEnd + (availableStart === availableEnd ? 1 : 0))
    );
  }

  function getDayOfWeek(dateString) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(dateString);
    const dayOfWeekIndex = date.getDay();
    return daysOfWeek[dayOfWeekIndex];
  }

  useEffect(() => {
    // Retrieve venueData from localStorage
    const token = localStorage.getItem("venueData");
    if (token) {
      setVenueData(JSON.parse(token));
    }
  }, []);

  useEffect(() => {
    fetchData();
    fetchPitchData();
  }, []);

  const fetchData = async () => {
    try {
      // Fetch booking options
      const bookingResponse = await axios.post(
        "https://devmindmedia.nestorhawk.com/api/method/mindmedia.api.booking.api.get_booking_for",
        { venue: venueData.data.name }
      );
      const bookingOptions = bookingResponse.data.data || [];
      setBookingOptions(bookingOptions);
      // Fetch pitches
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function formatDateforGetAlreadyBookedPitches(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function getNextDate(dateString) {
    const currentDate = new Date(dateString);
    currentDate.setDate(currentDate.getDate() + 1); // Adding one day
    return currentDate.toISOString().split("T")[0]; // Convert to ISO date string (YYYY-MM-DD)
  }

  const fetchAlreadyBookedPitches = async (selectedPitch) => {
    try {
      let payload = {};
      if (selectedTab === "day") {
        payload = {
          venue: venueData.data.name,
          booking_for: selectedPitch,
          start_date: formatDateforGetAlreadyBookedPitches(selectedDate),
          end_date: getNextDate(
            formatDateforGetAlreadyBookedPitches(selectedDate)
          ),
        };
      } else {
        payload = {
          venue: venueData.data.name,
          booking_for: selectedPitch,
          start_date: formatDateforGetAlreadyBookedPitches(startDate),
          end_date: formatDateforGetAlreadyBookedPitches(endDate),
        };
      }
      const res = await getAlreaduBookedPitches(payload);
      setbookedPitches(res.data.message);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (selectedPitch) {
      fetchAlreadyBookedPitches(selectedPitch);
    }
  }, [selectedPitch, selectedDate, startDate, selectedTab, endDate]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedPitch && venueData) {
        const fields = encodeURIComponent('["name"]');
        const venueFilter = encodeURIComponent(
          `["venue", "=", "${venueData.data.name}"]`
        );
        const pitchFilter = encodeURIComponent(
          `["booking_for", "=", "${selectedPitch}"]`
        );
        const filters = `[${venueFilter},${pitchFilter}]`;
        const limit_page_length = encodeURIComponent("1000");

        const url = `https://devmindmedia.nestorhawk.com/api/resource/Pitches?fields=${fields}&filters=${filters}&limit_page_length=${limit_page_length}`;

        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const data = await response.json();
          const fetchedPitches = data.data || [];
          setPitches(fetchedPitches);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [selectedPitch, venueData]);

  const handlePitchChange = (pitch) => {
    setSelectedPitch(pitch);
    setSelectOpen(true);
  };

  const handleSelectOpen = () => {
    setSelectOpen(true);
    fetchData();
  };

  const handleSelectClose = () => {
    setSelectOpen(false);
  };

  const handleSelectChange = (event) => {
    // Check if the newly selected pitch is different from the current pitch
    if (event.target.value !== selectedPitch) {
      // Clear the cart and update the selected pitch
      setCartItems([]);
      localStorage.removeItem("cartItems");
      setSelectedPitch(event.target.value);
      const temp = bookingOptions.find(
        (option) => option.name === event.target.value
      );
      setSelectedPitchName(temp.booking_for_name);
      setSelectOpen(false); // Close the dropdown after selecting an item
      fetchData();
    } else {
      // If the newly selected pitch is the same as the current pitch, just update the selected pitch
      setSelectedPitch(event.target.value);
      const temp = bookingOptions.find(
        (option) => option.name === event.target.value
      );
      setSelectedPitchName(temp.booking_for_name);
      setSelectOpen(false); // Close the dropdown after selecting an item
      fetchData();
    }
  };

  useEffect(() => {
    setVenueData((prevData) => ({ ...prevData, booking_for: selectedPitch }));
    if (
      selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "football" ||
      selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "basketball" ||
      selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "footboll"
    ) {
      setSelectedPitchIcon(FootballIcon);
    } else if (
      selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "ice bath" ||
      selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "icebath" ||
      selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "ice_bath"
    ) {
      setSelectedPitchIcon(IceBathIcon);
    } else if (
      selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "tenis" ||
      selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "badminton" ||
      selectedPitch?.toLowerCase()?.split("-").slice(-1)[0] === "pickleball"
    ) {
      setSelectedPitchIcon(TenisIcon);
    } else {
      setSelectedPitchIcon(PitchIcon);
    }
  }, [selectedPitch]);

  useEffect(() => {
    if (venueData) {
      localStorage.setItem("venueData", JSON.stringify(venueData));
    }
  }, [venueData]);

  const handleBoxClick = (pitch, time, weekview = false, date = undefined) => {
    const isSlotBooked = cartItems.some((bookedSlot) => {
      return (
        bookedSlot.name === pitch.name &&
        bookedSlot.time === time &&
        bookedSlot.date ===
          (weekview === true ? formatDate(date) : formatDate(selectedDate))
      );
    });

    if (isSlotBooked) {
      toast.warn("This slot is Already Booked");
    } else {
      // If slot is not booked, proceed with handling the click as before
      setShowPopup(true);
      setPopupInfo({
        pitch: pitch.name,
        time,
        date: weekview === true ? formatDate(date) : formatDate(selectedDate),
      });
    }
  };

  const handleAdd = () => {
    // Check if there's an adjacent item in the cart with the same pitch and date
    const adjacentItemIndex = cartItems.findIndex((item) => {
      // Check if the item has time defined
      if (!item.time) {
        return false;
      }

      const [startTime, endTime] = item.time.split("-");
      const [selectedStartTime, selectedEndTime] = popupInfo.time.split("-");

      // Check if the start time of the current item matches the end time of the selected item
      if (endTime === selectedStartTime) {
        return (
          item.pitch === popupInfo.pitch &&
          item.date === popupInfo.date &&
          endTime === selectedStartTime
        );
      }

      // Check if the end time of the current item matches the start time of the selected item
      if (startTime === selectedEndTime) {
        return (
          item.pitch === popupInfo.pitch &&
          item.date === popupInfo.date &&
          startTime === selectedEndTime
        );
      }

      return false;
    });

    // if (adjacentItemIndex !== -1) {
    //   // If an adjacent item is found, update its time slot accordingly
    //   const adjacentItem = cartItems[adjacentItemIndex];
    //   const startTime = adjacentItem.time.split("-")[0];
    //   const endTime = popupInfo.time.split("-")[1];
    //   const updatedItem = {
    //     ...adjacentItem,
    //     time: `${startTime}-${endTime}`,
    //   };

    //   // Update the cart items array with the updated item
    //   const updatedCartItems = [...cartItems];
    //   updatedCartItems.splice(adjacentItemIndex, 1, updatedItem);
    //   updateCartItems(updatedCartItems);
    // } else {
    // If no adjacent item is found, add the new item to the cart as usual
    if (selectedPitchName.substring(0, 2).toLowerCase() === "8a") {
      const no = pitche.findIndex((pitch) => pitch.name === popupInfo.pitch);
      if (
        no < pitche.length - 2 &&
        !isSlotSelected(pitche[no + 1], popupInfo.time) &&
        !isSlotSelected(pitche[no + 2], popupInfo.time)
      ) {
        const newItem = [
          {
            pitch: popupInfo.pitch,
            time: popupInfo.time,
            date: popupInfo.date,
            item_category: "Services",
            isnext: false,
            name: `${venueData.data.name} | ${
              popupInfo.pitch
            } | ${venueData.booking_for.split("-").pop()}`,
          },
          {
            pitch: pitche[no + 1].name,
            time: popupInfo.time,
            date: popupInfo.date,
            isnext: true,
            item_category: "Services",
            name: `${venueData.data.name} | ${
              pitche[no + 1].name
            } | ${venueData.booking_for.split("-").pop()}`,
          },
          {
            pitch: pitche[no + 2].name,
            time: popupInfo.time,
            date: popupInfo.date,
            isnext: true,
            item_category: "Services",
            name: `${venueData.data.name} | ${
              pitche[no + 2].name
            } | ${venueData.booking_for.split("-").pop()}`,
          },
        ];

        const newCartItems = [...cartItems, ...newItem];
        updateCartItems(newCartItems);
        // }
      } else {
        toast.error("8a pitch not available starting from this pitch");
      }
    } else {
      const newItem = [
        {
          pitch: popupInfo.pitch,
          time: popupInfo.time,
          date: popupInfo.date,
          item_category: "Services",
          name: `${venueData.data.name} | ${
            popupInfo.pitch
          } | ${venueData.booking_for.split("-").pop()}`,
        },
      ];

      const newCartItems = [...cartItems, ...newItem];
      updateCartItems(newCartItems);
    }

    setShowPopup(false);
    setPopupInfo({ pitch: null, time: null, date: null });
  };

  const removeFromCart = (name, time, date) => {
    if (selectedPitchName.substring(0, 2).toLowerCase() === "8a") {
      const no = pitche.findIndex((pitch) => pitch.name === name);

      let updatedCartItems = cartItems.filter(
        (item) =>
          item.pitch !== pitche[no].name ||
          item.time !== time ||
          item.date !== date
      );
      updatedCartItems = updatedCartItems.filter(
        (item) =>
          item.pitch !== pitche[no + 1].name ||
          item.time !== time ||
          item.date !== date
      );
      updatedCartItems = updatedCartItems.filter(
        (item) =>
          item.pitch !== pitche[no + 2].name ||
          item.time !== time ||
          item.date !== date
      );

      setCartItems(updatedCartItems);
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    } else {
      const updatedCartItems = cartItems.filter(
        (item) =>
          item.pitch !== name || item.time !== time || item.date !== date
      );
      setCartItems(updatedCartItems);
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    }
  };

  const isSlotSelected = (pitch, time, weekview = false, date = undefined) => {
    if (!pitch) return true;
    let temp = cartItems.some((item) => {
      const isSamePitch = item.pitch === pitch.name;
      const isSameDate =
        item.date ===
        (weekview === true ? formatDate(date) : formatDate(selectedDate));
      const isTimeOverlap = isTimeInRange(time, item.time);
      return isSamePitch && isSameDate && isTimeOverlap;
    });
    if (weekview && temp) {
    }
    return temp;
  };

  const isSlotAlreadyBooked = (
    pitch,
    time,
    weekview = false,
    date = undefined
  ) => {
    if (!pitch) return false;
    let temp = bookedPitches.some((item) => {
      const isSamePitch = item.custom_pitch === pitch.name;

      const isSameDate =
        formatDate(item.starts_on.split(" ")[0]) ===
        (weekview === true ? formatDate(date) : formatDate(selectedDate));

      const isTimeOverlap = isTimeInRange(
        time,
        formatTimeRange(item.starts_on, item.ends_on)
      );
      return isSamePitch && isSameDate && isTimeOverlap;
    });
    if (weekview && temp) {
    }
    return temp;
  };

  const isSlotDeleatable = (
    pitch,
    time,
    weekview = false,
    date = undefined
  ) => {
    //if (!pitch) return true;
    //if (!selectedPitchName.substring(0, 2).toLowerCase() === "8a") return true;

    let temp = cartItems.some((item) => {
      const isSamePitch = item.pitch === pitch.name;
      const isSameDate =
        item.date ===
        (weekview === true ? formatDate(date) : formatDate(selectedDate));
      const isTimeOverlap = isTimeInRange(time, item.time);
      const isdeletable = !item.isnext;
      return isSamePitch && isSameDate && isTimeOverlap && isdeletable;
    });

    return temp;
  };

  function formatTimeRange(startTime, endTime) {
    // Convert start time to 12-hour format
    let startParts = startTime.split(" ")[1].split(":");
    let startHour = parseInt(startParts[0]);
    let startMinutes = startParts[1];
    let startPeriod = startHour >= 12 ? "PM" : "AM";
    startHour = startHour % 12 || 12; // Convert 0 to 12 for 12-hour clock

    // Convert end time to 12-hour format
    let endParts = endTime.split(" ")[1].split(":");
    let endHour = parseInt(endParts[0]);
    let endMinutes = endParts[1];
    let endPeriod = endHour >= 12 ? "PM" : "AM";
    endHour = endHour % 12 || 12; // Convert 0 to 12 for 12-hour clock

    // Construct the formatted time range string
    let formattedStartTime = `${startHour}:${startMinutes} ${startPeriod}`;
    let formattedEndTime = `${endHour}:${endMinutes} ${endPeriod}`;
    let timeRange = `${formattedStartTime}-${formattedEndTime}`;

    return timeRange;
  }

  // Function to check if a given time is within the range of another time range
  const isTimeInRange = (time, range) => {
    if (!range) return false;
    // Parse the time values as hours, minutes, and meridiem (AM/PM)
    const timeMatch = time.match(/(\d+):(\d+) (AM|PM)/);
    const [selectedStartHour, selectedStartMinute, selectedStartMeridiem] =
      timeMatch.slice(1);
    const [slotStartHour, slotStartMinute, slotStartMeridiem] = range
      .split("-")[0]
      .match(/(\d+):(\d+) (AM|PM)/)
      .slice(1);
    const [slotEndHour, slotEndMinute, slotEndMeridiem] = range
      .split("-")[1]
      .match(/(\d+):(\d+) (AM|PM)/)
      .slice(1);

    // Convert hours to 24-hour format if necessary
    const selectedHour =
      parseInt(selectedStartHour) +
      (selectedStartMeridiem === "PM" && selectedStartHour !== "12" ? 12 : 0);
    const slotStart =
      parseInt(slotStartHour) +
      (slotStartMeridiem === "PM" && slotStartHour !== "12" ? 12 : 0);
    const slotEnd =
      parseInt(slotEndHour) +
      (slotEndMeridiem === "PM" && slotEndHour !== "12" ? 12 : 0);

    // Convert minutes to integers
    const selectedMinute = parseInt(selectedStartMinute);
    const slotStartMinuteInt = parseInt(slotStartMinute);
    const slotEndMinuteInt = parseInt(slotEndMinute);

    // Calculate the total minutes for the selected time
    const selectedTotalMinutes = selectedHour * 60 + selectedMinute;

    // Check if the selected time falls within the range
    if (slotStart <= slotEnd) {
      return (
        selectedTotalMinutes >= slotStart * 60 + slotStartMinuteInt &&
        selectedTotalMinutes < slotEnd * 60 + slotEndMinuteInt
      );
    } else {
      // If the range spans across AM/PM, consider it as two separate ranges
      return (
        (selectedTotalMinutes >= slotStart * 60 + slotStartMinuteInt &&
          selectedTotalMinutes < 24 * 60) ||
        (selectedTotalMinutes >= 0 &&
          selectedTotalMinutes < slotEnd * 60 + slotEndMinuteInt)
      );
    }
  };

  const updateCartItems = (newCartItems) => {
    setCartItems(newCartItems);
    localStorage.setItem("cartItems", JSON.stringify(newCartItems));
  };

  useEffect(() => {
    // Retrieve cartItems from localStorage on component mount
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  }, []);

  const handleClose = () => {
    setShowPopup(false);
    setPopupInfo({ pitch: null, time: null, date: null });
  };

  const visiblePitches = pitche.slice(
    startPitchIndex,
    startPitchIndex + pitchesPerPage
  );

  const handlePrevButtonClick = () => {
    if (startPitchIndex > 0) {
      setLoading(true);
      setStartPitchIndex(startPitchIndex - 1);
    }
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 50);

    return () => clearTimeout(timeoutId);
  };

  const handleNextButtonClick = () => {
    if (startPitchIndex + pitchesPerPage < pitche.length) {
      setLoading(true);

      setStartPitchIndex(startPitchIndex + 1);
    }
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 50);

    return () => clearTimeout(timeoutId);
  };

  const renderContent = () => {
    const boxStyle = {
      width: "150px",
      height: "40px",
      // border: "1px solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "5px",
    };

    const headingBoxStyle = {
      ...boxStyle,
    };

    if (selectedTab === "day") {
      const slots = [];
      return (
        <div className="flex mt-2 mb-12 pitches">
          <div className="ml-20 mr-5 pitch-day">
            {selectedPitch && (
              <div className="w-3/4">
                <div className="flex text-white">
                  <div className="flex-col timing">
                    <div className="flex justify-end">
                      <div
                        className="headingBoxStyle"
                        style={{ width: "30px" }}
                        onClick={handlePrevButtonClick}
                      >
                        {"<"}
                      </div>
                    </div>
                    {[...Array(blocksPerPitch)].map((_, blockIndex) => {
                      return (
                        <div
                          className="timings"
                          // style={{
                          //   ...boxStyle,
                          //   ...{
                          //     width: headingBoxStyle.width,
                          //     marginBottom: "10px",
                          //     marginTop: "5px",
                          //     color: "black",
                          //   },
                          // }}
                        >
                          {getTiming(blockIndex).replace("-", " ")}
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <div className="flex">
                      {visiblePitches.map((pitch, pitchIndex) => (
                        <div key={pitchIndex} className="headingBoxStyle">
                          {pitch.name}
                        </div>
                      ))}
                      <div
                        key={">"}
                        className="headingBoxStyle"
                        style={{ width: "30px" }}
                        onClick={handleNextButtonClick}
                      >
                        {">"}
                      </div>
                    </div>
                    <div>
                      {" "}
                      {[...Array(blocksPerPitch)].map((_, blockIndex) => {
                        return (
                          <div key={blockIndex} className="flex">
                            {visiblePitches.map((pitch, pitchIndex) => {
                              const slot = {
                                name: pitch.name,
                                time: getTiming(blockIndex),
                                date: formatDate(selectedDate),
                              };
                              slots.push(slot);

                              const isBooked = cartItems.some((bookedSlot) => {
                                return (
                                  bookedSlot.name === pitch.name &&
                                  bookedSlot.time === getTiming(blockIndex) &&
                                  bookedSlot.date === formatDate(selectedDate)
                                );
                              });

                              const alreadyBooked = isSlotAlreadyBooked(
                                pitch,
                                getTiming(blockIndex)
                              );

                              const isPitchAvailableday =
                                pitchWeekAvailability[
                                  getDayOfWeek(selectedDate)
                                ];
                              console.log(isPitchAvailableday);

                              const isPitchAvailable =
                                isPitchAvailableday &&
                                !isPitchAvailableday.some((slot) => {
                                  return checkIfTimeFitsInRangeoforDay(
                                    slot.start_time.slice(0, 2),
                                    slot.end_time.slice(0, 2),
                                    getTiming(blockIndex)
                                  );
                                });
                              return (
                                <div
                                  key={`${pitchIndex}-${blockIndex}`}
                                  style={{
                                    backgroundColor: isSlotSelected(
                                      pitch,
                                      getTiming(blockIndex)
                                    )
                                      ? "green"
                                      : alreadyBooked || isPitchAvailable
                                      ? "lightgray"
                                      : "white",
                                    borderColor:
                                      alreadyBooked || isPitchAvailable
                                        ? "#8F8F8F"
                                        : "#48c14c",
                                    cursor:
                                      isBooked ||
                                      isSlotSelected(
                                        pitch,
                                        getTiming(blockIndex)
                                      )
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                  onClick={() => {
                                    if (alreadyBooked || isPitchAvailable) {
                                    } else if (
                                      isSlotSelected(
                                        pitch,
                                        getTiming(blockIndex)
                                      ) &&
                                      isSlotDeleatable(
                                        pitch,
                                        getTiming(blockIndex)
                                      )
                                    ) {
                                      removeFromCart(
                                        pitch.name,
                                        getTiming(blockIndex),
                                        formatDate(selectedDate)
                                      );
                                    } else if (
                                      !isSlotSelected(
                                        pitch,
                                        getTiming(blockIndex)
                                      )
                                    ) {
                                      handleBoxClick(
                                        pitch,
                                        getTiming(blockIndex)
                                      );
                                    }
                                  }}
                                  className="pitch-box-style"
                                >
                                  <img
                                    src={selectedPitchIcon}
                                    alt="Pitch icon"
                                    style={{
                                      filter: isSlotSelected(
                                        pitch,
                                        getTiming(blockIndex)
                                      )
                                        ? "hue-rotate(180deg) brightness(200%)"
                                        : alreadyBooked || isPitchAvailable
                                        ? "brightness(90%) saturate(0%)"
                                        : "",
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else if (selectedTab === "week") {
      const boxStyleweek = {
        width: "170px",
        height: "35px",
        border: "1px solid #ccc",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "5px",
      };
      const boxStyleweeks = {
        width: "150px",
        height: "132px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "left",
        margin: "5px",
      };

      return (
        <div className="flex ml-20 items-center mb-12 week-calender">
          <div className="">
            <div className="mb-4 text-w">
              <div className="flex text-white">
                <div className="flex-col time-col-week">
                  <div className="flex justify-end">
                    <div
                      className="headingBoxStyle"
                      style={{ width: "30px" }}
                      onClick={prevWeek}
                    >
                      {"<"}
                    </div>
                  </div>
                  {[...Array(blocksPerPitch)].map((_, blockIndex) => {
                    return (
                      <div className="timings-week">
                        {getTiming(blockIndex).replace("-", " ")}
                      </div>
                    );
                  })}
                </div>
                <div>
                  <div className="flex">
                    {[...Array(7)].map((_, index) => (
                      <div
                        key={index}
                        style={{ ...headingBoxStyle, width: boxStyle.width }}
                        className="headingBoxStyle"
                      >
                        {formatDate(
                          new Date(
                            startDate.getFullYear(),
                            startDate.getMonth(),
                            startDate.getDate() + index
                          )
                        )}
                      </div>
                    ))}
                    <div
                      key={">"}
                      className="headingBoxStyle"
                      style={{ width: "30px" }}
                      onClick={nextWeek}
                    >
                      {">"}
                    </div>
                  </div>
                  <div>
                    {[...Array(blocksPerPitch)].map((_, blockIndex) => (
                      <div key={blockIndex} className="flex">
                        {/* Render blocks for each day of the week */}
                        {[...Array(7)].map((_, dayIndex) => {
                          const isPitchAvailableday =
                            pitchWeekAvailability[
                              getDayOfWeek(
                                formatDate(
                                  new Date(
                                    startDate.getFullYear(),
                                    startDate.getMonth(),
                                    startDate.getDate() + dayIndex
                                  )
                                )
                              )
                            ];

                          const isPitchAvailable =
                            isPitchAvailableday &&
                            !isPitchAvailableday.some((slot) => {
                              return checkIfTimeFitsInRangeoforDay(
                                slot.start_time.slice(0, 2),
                                slot.end_time.slice(0, 2),
                                getTiming(blockIndex)
                              );
                            });

                          return (
                            <div
                              key={dayIndex}
                              style={boxStyleweeks}
                              className="boxStyleWeeks"
                            >
                              {/* Render pitches for each day */}
                              {pitche.map((pitch, pitchIndex) => {
                                const alreadyBooked = isSlotAlreadyBooked(
                                  pitch,
                                  getTiming(blockIndex),
                                  true,
                                  new Date(
                                    startDate.getFullYear(),
                                    startDate.getMonth(),
                                    startDate.getDate() + dayIndex
                                  )
                                );

                                return (
                                  <div
                                    key={pitchIndex}
                                    className="pitch-week-box-style"
                                    style={{
                                      backgroundColor: isSlotSelected(
                                        pitch,
                                        getTiming(blockIndex),
                                        true,
                                        new Date(
                                          startDate.getFullYear(),
                                          startDate.getMonth(),
                                          startDate.getDate() + dayIndex
                                        )
                                      )
                                        ? "green"
                                        : alreadyBooked || isPitchAvailable
                                        ? "lightgray"
                                        : "white",

                                      cursor: isSlotSelected(
                                        pitch,
                                        getTiming(blockIndex),
                                        true,
                                        new Date(
                                          startDate.getFullYear(),
                                          startDate.getMonth(),
                                          startDate.getDate() + dayIndex
                                        )
                                      )
                                        ? "not-allowed"
                                        : "pointer",
                                      border: isSlotSelected(
                                        pitch,
                                        getTiming(blockIndex),
                                        true,
                                        new Date(
                                          startDate.getFullYear(),
                                          startDate.getMonth(),
                                          startDate.getDate() + dayIndex
                                        )
                                      )
                                        ? "none"
                                        : alreadyBooked || isPitchAvailable
                                        ? "1px solid #8F8F8F"
                                        : "",
                                      width: "auto",
                                    }}
                                  >
                                    {/* Checkbox */}
                                    <div
                                      className="sm:text-small flex"
                                      onClick={() => {
                                        alreadyBooked || isPitchAvailable
                                          ? {}
                                          : isSlotSelected(
                                              pitch,
                                              getTiming(blockIndex),
                                              true,
                                              new Date(
                                                startDate.getFullYear(),
                                                startDate.getMonth(),
                                                startDate.getDate() + dayIndex
                                              )
                                            ) &&
                                            isSlotDeleatable(
                                              pitch,
                                              getTiming(blockIndex),
                                              true,
                                              new Date(
                                                startDate.getFullYear(),
                                                startDate.getMonth(),
                                                startDate.getDate() + dayIndex
                                              )
                                            )
                                          ? removeFromCart(
                                              pitch.name,
                                              getTiming(blockIndex),
                                              formatDate(
                                                new Date(
                                                  startDate.getFullYear(),
                                                  startDate.getMonth(),
                                                  startDate.getDate() + dayIndex
                                                )
                                              )
                                            )
                                          : handleBoxClick(
                                              pitch,
                                              getTiming(blockIndex),
                                              true,
                                              new Date(
                                                startDate.getFullYear(),
                                                startDate.getMonth(),
                                                startDate.getDate() + dayIndex
                                              )
                                            );
                                      }}
                                    >
                                      <div
                                        className="pitch-name-week"
                                        style={{
                                          color: isSlotSelected(
                                            pitch,
                                            getTiming(blockIndex),
                                            true,
                                            new Date(
                                              startDate.getFullYear(),
                                              startDate.getMonth(),
                                              startDate.getDate() + dayIndex
                                            )
                                          )
                                            ? "white"
                                            : alreadyBooked || isPitchAvailable
                                            ? "#8F8F8F"
                                            : "green",
                                        }}
                                      >
                                        {pitch.name}
                                      </div>
                                      <img
                                        src={selectedPitchIcon}
                                        alt="Pitch icon"
                                        className="w-4 h-4"
                                        style={{
                                          filter: isSlotSelected(
                                            pitch,
                                            getTiming(blockIndex),
                                            true,
                                            new Date(
                                              startDate.getFullYear(),
                                              startDate.getMonth(),
                                              startDate.getDate() + dayIndex
                                            )
                                          )
                                            ? "hue-rotate(180deg) brightness(200%)"
                                            : alreadyBooked || isPitchAvailable
                                            ? "brightness(90%) saturate(0%)"
                                            : "",
                                        }}
                                      />
                                    </div>
                                    {/* Pitch name */}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  if (!venueData) {
    return <div>Loading...</div>;
  }

  const handleBookNow = () => {
    if (venueData) {
      // Save movie data to localStorage before navigating
      localStorage.setItem("venueData", JSON.stringify(venueData));

      navigate(
        `/venue-listing-page/venue/${encodeURIComponent(
          venueData.data.name
        )}/booking-page/accessories`
      );
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex flex-col min-h-screen">
        {" "}
        <div className="flex-grow ">
          {" "}
          <header>
            <div className="flex justify-between items-center mt-4">
              <h1 className="text-4xl font-bold text-start ml-20 mb-4 relative pitch-name-address">
                {venueData.data.name}
                <br /> {/*venue number */}
                <h6 className="relative text-sm">{venueData.data.address}</h6>
              </h1>
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 sm:px-2 rounded mr-20 view-cart-button"
                onClick={() => {
                  if (cartItems.length > 0) {
                    handleBookNow();
                  } else {
                    toast.warn("No items in the cart");
                  }
                }}
              >
                Proceed to Checkout ({cartItems.length})
              </button>
            </div>
          </header>
          <div className="text-4xl font-bold text-start ml-20 booking-for">
            <FormControl sx={{ m: 1, minWidth: 150 }}>
              <InputLabel id="demo-select-small-label" className="">
                Booking for
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Booking to"
                placeholder="Booking for"
                sx={{ height: "40px" }}
                open={selectOpen}
                onClose={handleSelectClose}
                onOpen={handleSelectOpen}
                onChange={handleSelectChange}
              >
                {bookingOptions.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.booking_for_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="text-4xl font-bold text-start ml-20 relative pitches-shown">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-input-small-label">Pitches</InputLabel>
              <Input
                labelId="demo-input-small-label"
                id="demo-input-small"
                label="Pitche"
                sx={{ height: "42px" }}
                className="pitch-option flex items-center  justify-center border border-gray-300 rounded-md"
                onClick={() => handlePitchChange(selectedPitch)}
                enabled
                value={pitche.map((pitch) => pitch.name).join(", ")}
                readOnly
              />
            </FormControl>
          </div>
          {selectedPitch && (
            <div className="flex items-center date-tabs justify-content-between">
              {selectedTab === "day" ? (
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  placeholderText="Select a date"
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  className="border text-center border-gray-300 ml-48 mt-10 rounded-lg p-2 h-12 data-picker"
                />
              ) : (
                <div className="week-selector text-center ml-20 mt-10 rounded-lg p-2 h-12 data-picker-week">
                  <button onClick={prevWeek}>
                    <img src={LeftButton} alt="Left Navigation button" />
                  </button>
                  <h4 className="week-range">
                    {formatDate(startDate)} - {formatDate(endDate)}
                  </h4>
                  <button onClick={nextWeek}>
                    <img src={RightButton} alt="Right Navigation button" />
                  </button>
                </div>
              )}

              {selectedDate && <button onClick={clearDate}></button>}
              <Tabs
                value={selectedTab}
                onChange={(event, newValue) => setSelectedTab(newValue)}
                className="rounded-lg border border-gray-300 mt-10 ml-3 h-8 date-tabs mr-16"
              >
                <Tab label="Day" value="day" className="rounded-lg" />
                <Tab label="Week" value="week" className="rounded-lg" />
              </Tabs>
            </div>
          )}
          {loading ? <LoadingBackdrop open={loading} /> : renderContent()}
          <div className="flex justify-end mb-12">
            <button
              className="bg-red-500 hover:bg-red-700 text-white  font-bold py-2 px-4 sm:px-2 rounded mr-20 view-cart-button"
              onClick={() => {
                if (cartItems.length > 0) {
                  handleBookNow();
                } else {
                  toast.warn("No items in the cart");
                }
              }}
            >
              Proceed to Checkout ({cartItems.length})
            </button>
          </div>
          {showPopup && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
              <div className="bg-white p-8 rounded-lg z-10">
                <h2 className="text-2xl font-bold mb-4">
                  Pitch you Selected:{popupInfo.pitch}
                </h2>
                <p className="mb-2">Time: {popupInfo.time}</p>
                <p className="mb-4">Date: {formatDate(popupInfo.date)}</p>
                <div className="flex justify-between">
                  <button
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleAdd}
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PitchSelectionTable;
