import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import profileIcon from "../../assets/images/defaultProfilePic.jpg";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { CheckCircle } from "lucide-react";
import "../index.css";
import {
  fetchUserDatasApi,
  uploadProfilePicApi,
  requestSignupOTPApi,
  verifySignupOTPApi,
  setNewMobileApi,
} from "../../handlers/myaccount/api";

function Profile() {
  const [userData, setUserData] = useState(() => {
    const storedUserData = localStorage.getItem("userData");
    return storedUserData
      ? JSON.parse(storedUserData)
      : {
          email: "",
          phone: "",
          image: profileIcon,
          selectedImage: null,
        };
  });
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [otp, setOtp] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);

  useEffect(() => {
    fetchAndStoreUserData();
  }, []);

  const fetchAndStoreUserData = () => {
    fetchUserData()
      .then((data) => {
        setUserData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(userData));
  }, [userData]);

  useEffect(() => {
    if (selectedImage) {
      localStorage.setItem("selectedImage", selectedImage);
    }
  }, [selectedImage]);

  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    try {
      const userData = await fetchUserDatasApi(token);
      return userData;
    } catch (error) {
      console.error(error.message);
      return {
        email: "",
        phone: "",
        image: profileIcon,
      };
    }
  };

  const handleProfilePicUpload = async () => {
    try {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";

      input.onchange = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = async () => {
          setSelectedImage(reader.result);

          try {
            const token = localStorage.getItem("token");
            const imageUrl = await uploadProfilePicApi(file, token);
            console.log(imageUrl);
            fetchAndStoreUserData();
            toast.success("Image Uploaded Successfully");

            setUserData((prevData) => ({
              ...prevData,
              image: imageUrl,
              selectedImage: reader.result,
            }));

            localStorage.setItem(
              "userData",
              JSON.stringify({
                ...userData,
                image: imageUrl,
                selectedImage: reader.result,
              })
            );
          } catch (error) {
            console.error(error.message);
          }
        };
      };

      input.click();
    } catch (error) {
      console.log(error.message);
    } finally {
      fetchAndStoreUserData();
    }
  };

  const handleChangePhoneNumber = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await requestSignupOTPApi(newPhoneNumber, token);

      if (response) {
        setIsEditingPhone(false);
        setShowOtpField(true);
        toast.success("OTP has been successfully sent");

        // Set showOtpField to false here
        setShowOtpField(false);
      } else {
        toast.warning("Failed to send OTP");
      }
    } catch (error) {
      console.error(error.message);
      toast.error("Failed to send OTP");
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const token = localStorage.getItem("token");

      const otpVerificationResponse = await verifySignupOTPApi(
        otp,
        newPhoneNumber,
        token
      );

      if (otpVerificationResponse) {
        toast.success("OTP Verified Successfully");

        const setPhoneNumberResponse = await setNewMobileApi(
          newPhoneNumber,
          token
        );

        if (setPhoneNumberResponse) {
          toast.success("Phone number updated successfully");
          setIsVerifying(true);
          // Perform actions after OTP verification and setting phone number
        } else {
          console.error("Failed to update phone number after OTP verification");
        }
      } else {
        console.error("Failed to verify OTP");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div>
      <div>
        <img
          src={userData.image}
          alt="Profile Icon"
          className="w-36 h-36  rounded-full cursor-pointer"
          onClick={handleProfilePicUpload}
        />
        <button
          className="ml-2 px-3 py-1 bg-red-500 mt-4 text-white font-semibold rounded hover:bg-red-600"
          onClick={handleProfilePicUpload}
        >
          Upload
        </button>
      </div>
      <div className="mt-6">
        <label className="text-base font-medium text-gray-900">Email</label>
        <button
          type="button"
          className="flex h-10 w-1/2 rounded-md  bg-transparent  py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50 relative"
          value={userData.email}
          readOnly
        >
          {userData.email}
          {!loading && (
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="text-green-500 ml-4 mt-1"
            />
          )}
        </button>
      </div>
      <div className="mt-6">
        <label className="text-base font-medium text-gray-900">Phone</label>
        {!isEditingPhone ? (
          <div className="flex ">
            <button
              type="button"
              className="flex h-10 rounded-md  bg-transparent  py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50 relative"
              value={userData.phone}
            >
              {newPhoneNumber || userData.phone}
              {!loading && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-green-500 mt-1 ml-4"
                />
              )}
            </button>

            <div className="" onClick={() => setIsEditingPhone(true)}>
              <FontAwesomeIcon
                icon={faPenToSquare}
                className="text-black-500 ml-4 mt-2"
              />
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            <input
              type="text"
              className="h-10 w-1/2 rounded-md py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1"
              placeholder="Enter new phone number"
              value={newPhoneNumber}
              onChange={(e) => setNewPhoneNumber(e.target.value)}
            />
            <button
              className="ml-2 px-3 h-10 py-1 bg-red-500 text-white font-semibold rounded hover:bg-red-600"
              onClick={handleChangePhoneNumber}
            >
              Send OTP
            </button>
          </div>
        )}
      </div>
      {showOtpField && (
        <div className="mt-6">
          <label className="text-base font-medium text-gray-900">
            Enter OTP
          </label>
          <div className="flex items-center">
            <input
              type="text"
              className="h-10 w-1/2 rounded-md border border-gray-300 px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <button
              className={`ml-2 px-3 h-10 py-1 bg-red-500 text-white font-semibold rounded hover:bg-red-600 ${
                isVerifying && "bg-green-500"
              }`}
              onClick={handleVerifyOTP}
              // disabled={isVerifying}
            >
              {isVerifying ? (
                <>
                  Verified <CheckCircle className="ml-1" size={12} />
                </>
              ) : (
                "verify"
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
