import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { forgotPassword } from '../../handlers/authentication/api';
import Navbar from '../../component/common/Navbar';
import Logo from '../../assets/images/company_logo_cricket.png'
import forgotPasswordImage from '../../assets/images/SignInBanner.png'; // Import your image here

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    setIsFormValid(isEmailValid);
  }, [email]);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setError('Please enter a valid email');
      return;
    }
    
    try {
      await forgotPassword(email); 
      console.log("Forgot Password request sent successfully");
      setSubmitted(true);
    } catch (error) {
      console.error("Failed to send Forgot Password request:", error);
      setError(error.message);
    }
  };
  
  return (
    <>
      <div className="flex justify-center w-full items-center">
        <div className="flex w-full items-center">
          <div className="flex-1 ">
            <img src={Logo} alt="Company Logo"  className="absolute top-0 left-2"/>;
            <div className="hug-w-657 hug-h-488 rounded-lg p-10 forgot-password-box">
              <h2 className="text-2xl font-semibold mb-4">Forgot Password</h2>
              <p>
              Enter your email for the verification proccess,we will send 4 digits code to your email.
              </p>
              {submitted ? (
                <p className="text-green-500 mb-4">
                  Please check the email address {email} <br/>for instructions to reset your password.
                </p>
              ) : (
                <>
                  {error && <p className="text-red-500 mb-4">{error}</p>}
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label
                        htmlFor="email"
                        className="block text-gray-700 font-bold mb-2"
                      >
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-indigo-500"
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      disabled={!isFormValid}
                      className={`inline-flex w-full items-center justify-center rounded-md bg-black px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-black/80 ${!isFormValid && 'opacity-50 cursor-not-allowed'}`}
                    >
                      Continue
                    </button>
                  </form>
                </>
              )}
            </div>
          </div>
          <div className="hidden sm:flex flex-1"> {/* Hide on small screens */}
            <img src={forgotPasswordImage} alt="Forgot Password" className="w-full h-auto" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
