import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  updateCardDetailsApi,
  getUserDetailsApi,
  deleteCardApi,
} from "../../handlers/myaccount/api";
import { toast } from "react-toastify";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import creditCardType from "credit-card-type";
import VisaLogo from "../../assets/images/visa-logo.png";
import MastercardLogo from "../../assets/images/master-card.png";

function CardAddingPage() {
  const [cards, setCards] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCardNumber, setNewCardNumber] = useState("");
  const [isPreferred, setIsPreferred] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const token = localStorage.getItem("token");

    getUserDetailsApi(token)
      .then((savedCards) => {
        setCards(savedCards);
      })
      .catch((error) => {
        console.error("Error fetching card details:", error);
      });
  }, []);

  const handleAddCard = () => {
    // Check if the new card number is empty or not 16 digits
    if (
      !newCardNumber ||
      newCardNumber.length !== 16 ||
      !/^\d+$/.test(newCardNumber)
    ) {
      toast.error("Error: Please enter a valid 16-digit card number.");
      return;
    }

    // Check if the new card number already exists in the list
    const isDuplicateCard = cards.some(
      (card) => card.card_number === newCardNumber
    );

    if (isDuplicateCard) {
      toast.error("Error: This card number already exists in the list.");
      return;
    }

    // Proceed with adding the new card
    const newCard = {
      card_number: newCardNumber,
      preferred_payment_method: isPreferred ? 1 : 0,
    };
    setCards((prevCards) => {
      const updatedCards = [...prevCards];
      if (isPreferred || updatedCards.length === 0) {
        updatedCards.forEach((card) => {
          card.preferred_payment_method = 0; // Deselect other cards
        });
        setIsPreferred(true);
      }
      return [...updatedCards, newCard];
    });

    setIsModalOpen(false);
  };

  const getCardLogo = (cardNumber) => {
    const cardInfo = creditCardType(cardNumber);

    if (cardInfo.length > 0) {
      const cardType = cardInfo[0].type;
      // You can return the card type and use it to display the appropriate logo
      switch (cardType) {
        case "visa":
          return VisaLogo; // Replace with the actual path to your Visa logo
        case "mastercard":
          return MastercardLogo; // Replace with the actual path to your Mastercard logo
        case "amex":
          return "amex-logo.png"; // Replace with the actual path to your Amex logo
        // Add cases for other card types as needed
        default:
          return VisaLogo; // Replace with a default logo or handle unknown card types
      }
    } else {
      // Handle cases where the card type cannot be determined
      return VisaLogo; // Replace with a placeholder for unknown logos
    }
  };

  const handleCardSelect = (index) => {
    const updatedCards = [...cards];
    updatedCards.forEach((card, i) => {
      card.selected = i === index;
      if (i === index) {
        card.preferred_payment_method = 1; // Select the clicked card and mark it as preferred
      } else {
        card.preferred_payment_method = 0; // Deselect other cards
      }
    });
    setCards(updatedCards);
  };

  const handleDeleteCard = (index) => {
    const deletedCard = cards[index];
    const updatedCards = cards.filter((card, i) => i !== index);

    // Check if there is at least one card remaining
    if (updatedCards.length === 0) {
      toast.warning(
        "Error deleting card: At least one card must be left for payment"
      );
      return;
    }

    // Check if the deleted card was preferred
    if (deletedCard.preferred_payment_method === 1 && updatedCards.length > 0) {
      updatedCards[0].preferred_payment_method = 1; // Set the first card as preferred if the deleted card was preferred
    }

    const token = localStorage.getItem("token");

    deleteCardApi(updatedCards, token)
      .then((response) => {
        toast.success("Card deleted successfully:", response);
        setCards(updatedCards);
      })
      .catch((error) => {
        console.error("Error deleting card:", error);
      });
  };

  const handleSubmit = () => {
    const formattedData = {
      card_details: cards.map((card) => ({
        card_number: card.card_number,
        preferred_payment_method: card.preferred_payment_method,
      })),
    };
    const token = localStorage.getItem("token");

    updateCardDetailsApi(formattedData, token)
      .then((response) => {
        toast.success("Payment Preference Added Successfully", response);
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };

  const formatCardNumber = (cardNumber) => {
    // Assuming the original format is "4545454545454545"
    // Change this logic based on your original card number format
    const formattedNumber = cardNumber.match(/.{1,4}/g).join("-");
    return formattedNumber;
  };

  return (
    <div className=" bg-white rounded-lg overflow-hidden  p-6">
      {cards.length === 0 ? (
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-red-700 hover:bg-red-800 text-white font-semibold py-2 px-4 rounded-lg mb-4"
        >
          Add Card
        </button>
      ) : (
        <div className="grid grid-cols-1 gap-4">
          {cards.map((card, index) => (
            <div
              key={index}
              className={`border flex border-gray-300 p-4 rounded-lg ${
                index === selectedCardIndex ? "border-2 border-blue-500" : ""
              }`}
            >
              <div className="mb-2 mr-5">
                {console.log(card.card_number, getCardLogo(card.card_number))}
                <img
                  src={getCardLogo(card.card_number)}
                  style={{
                    height: "40px",
                    width: "80px",
                    border: "1px solid #f0f0f0",
                  }}
                />
                {/* <CreditCardIcon className="mr-2" /> */}
                {/* Debit card icon */}
              </div>
              <div>
                <div className="text-lg font-semibold">
                  Card Ending in: {card.card_number.slice(-4)}
                </div>
                <div className="flex">
                  <label
                    className="flex items-center"
                    onClick={() => {
                      handleCardSelect(index);
                      setIsPreferred(true);
                    }}
                  >
                    {card.preferred_payment_method === 1
                      ? "Default"
                      : "Set as default"}
                  </label>
                  <button
                    onClick={() => handleDeleteCard(index)}
                    className="text-red-500 font-semibold py-2 px-4 rounded-lg "
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {isModalOpen && (
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <div className="relative bg-white rounded-lg overflow-hidden shadow-xl max-w-md w-full p-6">
              <button
                onClick={() => setIsModalOpen(false)}
                className="absolute top-0 right-0 p-2 text-gray-600 hover:text-gray-800"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <h2 className="text-xl font-semibold mb-4">Add Card</h2>
              <input
                type="text"
                placeholder="Card Number"
                value={newCardNumber}
                onChange={(e) => {
                  // Limit input to 16 characters
                  if (e.target.value.length <= 16) {
                    setNewCardNumber(e.target.value);
                  }
                }}
                className="w-full border rounded-lg px-3 py-2 mb-4"
              />

              <label className="flex items-center mb-4">
                <input
                  type="checkbox"
                  checked={isPreferred}
                  onChange={(e) => setIsPreferred(e.target.checked)}
                  className="mr-2"
                />
                Preferred Payment Method
              </label>
              <button
                onClick={handleAddCard}
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg"
              >
                Add Card
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-lg ml-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {cards.length > 0 && (
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg mt-4 "
        >
          Add Another Card
        </button>
      )}
      {cards.length > 0 && (
        <button
          onClick={handleSubmit}
          disabled={!isPreferred}
          className={`bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg mt-4 ml-4  ${
            !isPreferred ? " cursor-not-allowed" : ""
          }`}
        >
          Save
        </button>
      )}
    </div>
  );
}

export default CardAddingPage;
