import { ChevronRight } from "lucide-react";

import {
  FaFacebookSquare,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import React from "react";
import Logo from "../../assets/images/company_logo_cricket.png";

const Footer = () => {
  return (
    <footer className="w-full ">
      <div className="bg-[#F6E0E1] flex justify-between items-center px-6 py-4 lg:px-11 newsLetter">
        <div className="flex flex-col ml-12">
          <span className="text-lg  font-bold">Newsletter</span>
          <span>
            Be the first one to know about discounts, offers and events
          </span>
        </div>

        <div className="flex ml-auto newsletterSubmit">
          <form action="" className="flex rounded-26 items-center">
            <div style={{ position: "relative", display: "inline-block" }} className="newsletterSubmit">
              <input
                className="Footer-news h-10 w-80 rounded-full border border-[#A50007] bg-transparent px-3 py-2 text-sm placeholder:text-black"
                type="email"
                placeholder="Enter your Email"
                style={{ paddingRight: "2.5rem" }} // Added padding to accommodate the button
              />
              <button
                type="button"
                className="absolute top-1/2 transform -translate-y-1/2 right-1 w-20 h-8  rounded-full border-none bg-white text-[#ED1C25] text-xs font-semibold cursor-pointer"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="mx-auto flex max-w-7xl flex-col items-start space-x-8 md:flex-row mt-6">
        <div className="md:w-1/3">
          <img src={Logo} alt="Logo" className="h-15" />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </div>
        <div className="mt-8 grid grid-cols-2 gap-6 md:mt-0 lg:w-3/4 lg:grid-cols-3">
          <div className="mb-8 lg:mb-0">
            <p className="mb-6 text-lg font-semibold text-gray-700">Company</p>
            <ul className="flex flex-col space-y-4 text-[14px] font-medium text-gray-500">
              <a
                href="https://app.termly.io/document/privacy-policy/b4129fc2-180a-431e-a552-ae40e85864ed"
                target="blank"
              >
                About us
              </a>
              <a
                href="https://app.termly.io/document/privacy-policy/b4129fc2-180a-431e-a552-ae40e85864ed"
                target="blank"
              >
                Legal Information
              </a>
              <a
                href="https://app.termly.io/document/privacy-policy/b4129fc2-180a-431e-a552-ae40e85864ed"
                target="blank"
              >
                Contact Us
              </a>
              <a
                href="https://app.termly.io/document/privacy-policy/b4129fc2-180a-431e-a552-ae40e85864ed"
                target="blank"
              >
                Blogs
              </a>
            </ul>
          </div>
          <div className="mb-8 lg:mb-0">
            <p className="mb-6 text-lg font-semibold text-gray-700 ">
              HELP CENTER
            </p>
            <ul className="flex flex-col space-y-4 text-[14px] font-medium text-gray-500">
              <a
                href="https://app.termly.io/document/privacy-policy/b4129fc2-180a-431e-a552-ae40e85864ed"
                target="blank"
              >
                Find a Venue
              </a>
              <a
                href="https://app.termly.io/document/privacy-policy/b4129fc2-180a-431e-a552-ae40e85864ed"
                target="blank"
              >
                Why Us?
              </a>
              <a
                href="https://app.termly.io/document/privacy-policy/b4129fc2-180a-431e-a552-ae40e85864ed"
                target="blank"
              >
                FAQs
              </a>
              <a
                href="https://app.termly.io/document/privacy-policy/b4129fc2-180a-431e-a552-ae40e85864ed"
                target="blank"
              >
                Booking Guides
              </a>
            </ul>
          </div>

          <div className="mb-10 lg:mb-0">
            <p className="mb-6 text-lg font-semibold text-gray-700 ">
              Contact Info
            </p>
            <ul className="flex flex-col space-y-4 text-[14px] font-medium text-gray-500">
              <li>Phone : 6563449345</li>
              <li>Email : iwannaplay@thecage.com.sg</li>
              <li>
                Location :Knock Knock Food Delivery 3271 kristin ct columbus, OH
                43231 United States
              </li>
              <div className="container mx-auto flex">
                <a
                  href="https://www.facebook.com/"
                  target="blank"
                  className="mx-2"
                >
                  <FaFacebookSquare className="w-6 h-6" />
                </a>
                <a href="https://twitter.com/" target="blank" className="mx-2">
                  <FaTwitter className="w-6 h-6" />
                </a>
                <a
                  href="https://www.instagram.com/"
                  target="blank"
                  className="mx-2"
                >
                  <FaInstagram className="w-6 h-6" />
                </a>
                <a
                  href="https://www.linkedin.com/"
                  target="blank"
                  className="mx-2"
                >
                  <FaLinkedin className="w-6 h-6" />
                </a>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
