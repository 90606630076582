import { URL } from "./commonapi";

export const verify = "http://localhost:3000/verification/";
export const UpdateCard = `${URL}/api/method/mindmedia.api.my_account.api.update_card_details`;
export const UserGetDetails = `${URL}/api/method/mindmedia.api.my_account.api.get_user_details`;
export const UpdateProfilePic = `${URL}/api/method/mindmedia.api.my_account.api.update_profile_pic`;
export const SendOTP = `${URL}/api/method/mindmedia.api.api.request_signup_otp`;
export const VerifyOtp = `${URL}/api/method/mindmedia.api.api.verify_signup_otp`;
export const SetNewPhoneNumber = `${URL}/api/method/mindmedia.api.my_account.api.set_new_mobile`;
export const SendNotification = `${URL}/api/method/mindmedia.api.my_account.api.save_noti_setting`;
export const ForgottPassword = `${URL}/api/method/frappe.core.doctype.user.user.reset_password`;
export const Login = `${URL}/api/method/mindmedia.api.api.log_in`;
export const VerifyEmail = `${URL}/api/method/mindmedia.api.api.send_verification_mail`;
export const SignUp = `${URL}/api/method/mindmedia.api.api.sign_up`;
export const venueListingPage = `${URL}/api/resource/Venue`;
export const getProducts = `${URL}/api/method/mindmedia.api.booking.api.get_products_based_on_item_group`;
export const CreateOrder = `${URL}/api/method/mindmedia.api.booking.api.create_order_and_event`;
export const UpdatePhoneNumber = `${URL}/api/method/mindmedia.api.my_account.api.set_new_mobile`;
export const ItemDetails = `${URL}/api/method/mindmedia.api.booking.api.get_product_details`;
export const BookingHistory = `${URL}/api/method/mindmedia.api.booking.api.booking_history`;
export const RequestPayment = `${URL}/api/method/mindmedia.mindmedia.doctype.reddot_payment_setting.reddot_payment_setting.request_payment_url`;
export const handlePayment = `${URL}/api/method/mindmedia.mindmedia.doctype.reddot_payment_setting.reddot_payment_setting.handle_redirect`;
export const create_si_and_payment_api = `${URL}/api/method/mindmedia.api.booking.api.create_si_and_payment_api`;
export const checkIfPaymentAlreadyDone = `${URL}/api/method/mindmedia.api.booking.api.not_create_duplicate_si`;
export const AlreadyBookedPitch = `${URL}/api/method/mindmedia.api.booking.endpoints.slot_booking.get_event`;
export const SearchActivity = `${URL}/api/method/mindmedia.api.booking.api.get_uniq_bookingfor_for_filters`
