import React, { useState, useEffect } from "react";
import { ArrowRight, Eye, EyeOff } from "lucide-react"; // Import Eye and EyeOff icons
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GoogleLogo from "../../assets/images/google_logo.svg";
import Logo from "../../assets/images/company_logo_cricket.png";
import { signIn } from "../../handlers/authentication/api";
import Navbar from "../../component/common/Navbar";
import RightBanner from "./RightBanner";

const SignIn = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

  useEffect(() => {
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isPasswordValid = password.length >= 6;

    setIsFormValid(isEmailValid && isPasswordValid);
  }, [email, password]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setError("Please enter a valid email and password");
      return;
    }

    try {
      const data = await signIn(email, password);
      if (data.token) {
        localStorage.setItem("token", data.token);
        setIsLoggedIn(true);
        toast.success("Successfully Log In");
      } else {
        throw new Error("User Not Found");
      }
    } catch (error) {
      console.error("Error signing in:", error.message);
      toast.warning(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    // Google Sign-in code here
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} />
      <section className="min-h-screen flex items-stretch justify-center signup">
        <div className="app-container">
          <div className="w-full signup-form-container p-10">
            <div className="signUpLogo">
              <img src={Logo} alt="Logo" className="top-0 left-0" />
            </div>
            <div className="form-body xl:p-20">
              <h2 className="text-2xl font-bold leading-tight text-black">
                Sign in to your account
              </h2>
              <div className="mt-4 space-y-3">
                {/* Google Sign-in Button */}
                <button
                  type="button"
                  className="button relative inline-flex w-full items-center justify-center gap-3 rounded-md border googleSignup border-gray-800 px-3 py-2 font-semibold text-gray-700 transition-all duration-50 hover:bg-gray-100 hover:text-black focus:bg-gray-100 focus:text-black focus:outline-none text-sm"
                  onClick={handleGoogleSignIn}
                >
                  <img
                    src={GoogleLogo}
                    alt="Google Sign Up"
                    className="google_logo"
                    height="30px"
                    width="30px"
                  />
                  Login with Google
                </button>
              </div>
              <form onSubmit={handleSignIn} className="mt-8">
                <div className="space-y-5">
                  <div>
                    <label
                      htmlFor="email"
                      className="text-base font-medium text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type="email"
                        placeholder="Email"
                      ></input>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="password"
                        className="text-base font-medium text-gray-900"
                      >
                        Password
                      </label>
                      
                    </div>
                    <div className="mt-2">
                      <input
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type={showPassword ? "text" : "password"} // Set input type dynamically based on showPassword state
                        placeholder="Password"
                      ></input>
                      {/* Toggle Password Visibility Button */}
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="focus:outline-none"
                      >
                        {showPassword ? (
                          <EyeOff size={20} />
                        ) : (
                          <Eye size={20} />
                        )}
                      </button>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      disabled={!isFormValid}
                      className={`inline-flex w-full items-center justify-center rounded-md bg-black px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-black/80 ${
                        !isFormValid && "opacity-50 cursor-not-allowed"
                      }`}
                    >
                      Get started <ArrowRight className="ml-2" size={16} />
                    </button>
                  </div>
                  {error && <p className="text-red-500">{error}</p>}
                </div>
              </form>
              {/* Sign-Up Link */}
              <div className="flex justify-center mt-4 text-center ">
                <Link
                  to="/sign-in/forgot-password"
                  className="text-gray-500 font-semibold no-underline inline-block w-40"
                >
                  Forgot Password?
                </Link>
              </div>
              <div className="flex justify-center mt-4 text-center ">
                <p className="font-semibold text-lg text-gray-600 ">
                  Don&apos;t have an account?
                  <Link
                    to="/sign-up"
                    className=" text-red-500 no-underline ml-3 hover:text-black"
                  >
                    Create Account
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <RightBanner />
        </div>
      </section>
    </>
  );
};

export default SignIn;
