import axios from "axios";
import {
  RequestPayment,
  checkIfPaymentAlreadyDone,
  create_si_and_payment_api,
  handlePayment,
} from "../common/api";

export const requestPayment = async (payload) => {
  console.log(payload);
  try {
    const response = await axios.post(RequestPayment, payload);

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to request payment");
    }
  } catch (error) {
    throw new Error("Error getiing request payment", error);
  }
};

export const handleRedirect = async (payload) => {
  console.log(payload, "Payload");
  try {
    const response = await axios.post(handlePayment, payload);

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to redirect payment");
    }
  } catch (error) {
    throw new Error("Error getiing redirect", error);
  }
};

export const Create_si_and_payment_api = async (payload, token) => {
  console.log(payload, "Payload");
  try {
    const response = await axios.post(create_si_and_payment_api, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${token}`,
      },
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to redirect payment");
    }
  } catch (error) {
    throw new Error("Error getiing redirect", error);
  }
};

export const CheckIfPaymentAlreadyDone = async (payload, token) => {
  console.log(payload, "Payload");
  try {
    const response = await axios.post(
      checkIfPaymentAlreadyDone,
      { order_number: payload },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to redirect payment");
    }
  } catch (error) {
    throw new Error("Error getiing redirect", error);
  }
};
