import React, { useState, useEffect } from "react";
import { ArrowRight, CheckCircle } from "lucide-react";
import { Link, useLocation, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Logo from "../../assets/images/company_logo_cricket.png";
import GoogleLogo from "../../assets/images/google_logo.svg";
import Navbar from "../../component/common/Navbar";
import {
  verifyEmail,
  sendOTP,
  verifyOTP,
  signUp,
} from "../../handlers/authentication/api";
import { TextField } from "@material-ui/core";
import RightBanner from "./RightBanner";

const SignUp = () => {
  const location = useLocation();

  const userData = location.state ? location.state.formData || {} : {};
  const [formData, setFormData] = useState({
    full_name: userData.full_name || "",
    email: userData.email || "",
    phone: userData.phone || "",
    otp: userData.otp || "",
    countrycode: userData.countrycode || "",
    verifiedEmail: userData.verifiedEmail || 0,
  });

  useEffect(() => {
    if (userData.verifiedEmail) {
      setEmailVerified(true);
    }
  }, [userData.verifiedEmail]);

  const [errors, setErrors] = useState({
    full_name: "",
    email: "",
    phone: "",
    otp: "",
  });
  const [emailVerified, setEmailVerified] = useState(false);
  const [sentotp, setSentOtp] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSignIn, setSignIn] = useState(false);

  useEffect(() => {
    if (location.state) {
      const storedFormData = location.state.formData || {};
      setFormData(storedFormData);
      setEmailVerified(true);
    } else {
      setFormData({});
    }
  }, [location.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });

    const isFullNameFilled =
      name === "fullName" ? value !== "" : formData.full_name !== "";
    const isEmailFilled =
      name === "email" ? value !== "" : formData.email !== "";
    const isPhoneFilled =
      name === "phone" ? value !== "" : formData.phone !== "";
    const isOtpFilled = name === "otp" ? value !== "" : formData.otp !== "";

    setFormValid(
      isFullNameFilled && isEmailFilled && isPhoneFilled && isOtpFilled
    );
  };

  const handleVerifyEmail = async () => {
    try {
      localStorage.setItem("signupFormData", JSON.stringify(formData));

      const res = await verifyEmail(formData.email, formData.full_name);
      if (res.message.status_code === 200) {
        toast.success("Verification link sent successfully to your email.");
        setEmailVerified(true); // Set email verification status to true
        setTimeout(() => {
          window.close();
        }, 2000);
      } else if (res.message.status_code === 409) {
        toast.warning("Email is already verified.");
      } else {
        toast.error(
          "Failed to send verification link. Please try again later."
        );
      }
    } catch (error) {
      console.error("Failed to send verification email:", error);
    }
  };

  const handleSendOTP = async () => {
    try {
      await sendOTP(`+91${formData.phone}`);
      setOtpSent(true);
      setSentOtp(true);
      toast.success("OTP Successfully Sent to your Registered Phone Number");
    } catch (error) {
      console.error("Failed to send OTP:", error);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const res = await verifyOTP(`+91${formData.phone}`, formData.otp);
      setOtpVerified(true);
      toast.success("OTP verified Successfully");
    } catch (error) {
      toast.warning("Invalid OTP");
      console.error("OTP verification failed:", error);
    }
  };

  const handleGoogleSignIn = async () => {
    // Google Sign-in code here
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const statusCode = await signUp(
        formData.email,
        `+91${formData.phone}`,
        formData.full_name
      );

      if (statusCode === 200) {
        toast.success("SignUp Success Please check email for set password");
        setTimeout(() => {
          window.close();
        }, 4000);
      } else if (statusCode === 409) {
        toast.warning("Already Registered");
      } else {
        toast.warning(
          "Failed to send verification link. Please try again later."
        );
      }
    } catch (error) {
      console.error("Failed to send verification email:", error);
    }
  };

  useEffect(() => {
    localStorage.removeItem("signupFormData");
  }, []);

  if (isSignIn) {
    return <Navigate to="/my-account" />;
  }

  return (
    <>
      <section className="min-h-screen flex items-stretch justify-center signup">
        <div className="app-container">
          <div className="w-full signup-form-container p-10">
            <div className="signUpLogo">
              <img src={Logo} alt="Logo" className="top-0 left-0" />
            </div>
            <div className="form-body xl:p-20">
              <h2 className="text-2xl font-bold leading-tight text-black">
                Sign Up
              </h2>
              <p className="mt-2 text-left create-account">
                Create an account to book an amazing experience.
              </p>
              <div className="mt-4 space-y-3">
                {/* Google Sign-in Button */}
                <button
                  type="button"
                  className="button relative inline-flex w-full items-center justify-center gap-3 rounded-md border googleSignup border-gray-800 px-3 py-2 font-semibold text-gray-700 transition-all duration-50 hover:bg-gray-100 hover:text-black focus:bg-gray-100 focus:text-black focus:outline-none text-sm"
                  onClick={handleGoogleSignIn}
                >
                  <img
                    src={GoogleLogo}
                    alt="Google Sign Up"
                    className="google_logo"
                    height="30px"
                    width="30px"
                  />
                  Continue with Google
                </button>
              </div>
              {/* Email Sign-up Form */}
              <p className="text-center mt-4">
                <span className="text-gray-400 font-semibold uppercase inline-block">or</span>
              </p>
              <form
                action="#"
                method="POST"
                className="w-full"
                onSubmit={handleSubmit}
              >
                {/* Full Name */}
                <div className="row">
                  <div className="signIn">
                    <input
                      className="SignInInput"
                      type="text"
                      placeholder=" "
                      id="full_name"
                      name="full_name"
                      value={formData.full_name}
                      onChange={handleChange}
                    />
                    {errors.full_name && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.full_name}
                      </p>
                    )}
                    <label className="promo-label">Full Name</label>
                  </div>
                </div>
                {/* Email Address */}
                <div className="row flex-row">
                  <div className="col-md-8 signIn">
                    <input
                      className="SignInInput"
                      placeholder=" "
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.email}
                      </p>
                    )}
                    <label className="promo-label">Email</label>
                  </div>
                  {/* Verified Email Button */}
                  {emailVerified && (
                    <div className="col-md-4">
                      <button
                        type="button"
                        className="inline-block w-full text-white bg-green-500 px-4 verify_btn rounded-md font-semibold hover:bg-green-600 transition duration-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                        disabled
                      >
                        Verified
                      </button>
                    </div>
                  )}
                  {/* Email Verification Button */}
                  {!emailVerified && (
                    <div className="col-md-4">
                      <button
                        type="button"
                        onClick={handleVerifyEmail} // Call handleVerifyEmail function
                        className="inline-block w-full text-white bg-black px-4 verify_btn rounded-md font-semibold hover:bg-opacity-50 transition duration-200 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50"
                      >
                        Verify Email
                      </button>
                    </div>
                  )}
                </div>
                {/* Phone Number */}
                <div className="row">
                  <div className=" col-md-8 signIn">
                    <input
                      className="SignInInput"
                      placeholder=" "
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    {errors.phone && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.phone}
                      </p>
                    )}
                    <label className="promo-label">Phone</label>
                  </div>
                  {!otpSent && (
                    <div className="col-md-4">
                      <button
                        type="button"
                        onClick={handleSendOTP} // Call handleSendOTP function
                        className="inline-block w-full text-white bg-black px-4 verify_btn rounded-md font-semibold hover:bg-opacity-50 transition duration-200 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50"
                      >
                        Send OTP
                      </button>
                    </div>
                  )}
                  {/* Verify OTP Button */}
                  {otpSent && (
                    <div className="col-md-4">
                      <button
                        type="button"
                        onClick={handleVerifyOTP}
                        className="inline-block w-full text-white bg-black px-4 verify_btn rounded-md font-semibold hover:bg-opacity-50 transition duration-200 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50"
                      >
                        Verify OTP
                      </button>
                    </div>
                  )}
                </div>
                {/* OTP Input */}
                <div className="row">
                  <div className="signIn">
                    <input
                      className="SignInInput"
                      placeholder=" "
                      id="otp"
                      name="otp"
                      value={formData.otp}
                      onChange={handleChange}
                    />
                    {errors.otp && (
                      <p className="text-red-500 text-xs mt-1">{errors.otp}</p>
                    )}
                    <label className="promo-label">OTP</label>
                  </div>
                </div>

                {/* Submit Button */}
                <div className="flex justify-center mt-4">
                  <button
                    id="create-account-button"
                    type="submit"
                    className={`inline-flex w-50 items-center justify-center rounded-md bg-#ED1C25 px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-black/80 ${
                      formValid ? "" : "cursor-not-allowed opacity-50"
                    }`}
                    disabled={!formValid}
                  >
                    Create Account <ArrowRight className="ml-2" size={25} />
                  </button>
                </div>
              </form>
              {/* Sign-in Link */}
              <div className="flex justify-center mt-4 text-center ">
                <p className="text-sm text-gray-600">
                  Already have an account?
                  <Link
                    to="/sign-in"
                    className="font-medium text-red-500 no-underline ml-3 hover:text-black"
                  >
                    Log In
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <RightBanner />
        </div>
      </section>
    </>
  );
};

export default SignUp;
