import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../component/common/NavbarProfile";
import Profile from "./Profile";
import Bookings from "./Bookings";
import PaymentMethods from "./PaymentMethods";
import NotificationSettings from "./NotificationSettings";
import { useNavigate } from "react-router-dom";

const MyAccount = ({ toggleSidebar, isOpen }) => {
  const [selectedSection, setSelectedSection] = useState("profile");
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleSectionChange = (section) => {
    setSelectedSection(section);
    toggleSidebar();
  };

  const renderSection = () => {
    switch (selectedSection) {
      case "profile":
        return <Profile />;
      case "bookings":
        return <Bookings />;
      case "paymentMethods":
        return <PaymentMethods />;
      case "notificationSettings":
        return <NotificationSettings />;
      default:
        return null;
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("cartItems");
    navigate("/");
  };

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="flex">
        {/* Sidebar */}
        {(isOpen || !isMobile) && (
          <div
            className={`h-full w-64 bg-white text-black transition-all duration-300 ${
              isOpen || !isMobile ? "translate-x-0" : "-translate-x-full"
            }`}
          >
            <ul className="pl-3">
              <li className="mb-2">
                <button
                  onClick={() => handleSectionChange("profile")}
                  className={`${
                    selectedSection === "profile"
                      ? "bg-red-100 rounded-full"
                      : ""
                  } text-black-500 hover:text-blue-700 focus:outline-none py-2 px-4`}
                >
                  My Profile
                </button>
              </li>
              <li className="mb-2">
                <button
                  onClick={() => handleSectionChange("bookings")}
                  className={`${
                    selectedSection === "bookings"
                      ? "bg-red-100 rounded-full"
                      : ""
                  } text-black-500 hover:text-blue-700 focus:outline-none py-2 px-4`}
                >
                  Bookings
                </button>
              </li>
              {/* <li className="mb-2">
                <button
                  onClick={() => handleSectionChange("paymentMethods")}
                  className={`${
                    selectedSection === "paymentMethods"
                      ? "bg-red-100 rounded-full"
                      : ""
                  } text-black-500 hover:text-blue-700 focus:outline-none py-2 px-4`}
                >
                  Payment Methods
                </button>
              </li> */}
              <li className="mb-2">
                <button
                  onClick={() => handleSectionChange("notificationSettings")}
                  className={`${
                    selectedSection === "notificationSettings"
                      ? "bg-red-100 rounded-full"
                      : ""
                  } text-black-500 hover:text-blue-700 focus:outline-none py-2 px-4`}
                >
                  Notification Settings
                </button>
              </li>
              {token && ( // Only render the logout button if token exists
                <li className="mb-2">
                  <button
                    onClick={handleLogout}
                    className={`${
                      selectedSection === "logout"
                        ? "bg-red-100 rounded-full"
                        : ""
                    } text-black-500 hover:text-blue-700 focus:outline-none py-2 px-4`}
                  >
                    LogOut
                  </button>
                </li>
              )}
            </ul>
          </div>
        )}
        {/* Main Content */}
        <div className="flex-1 p-4">{renderSection()}</div>
      </div>
    </>
  );
};

export default MyAccount;
