import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NearMeIcon from "@mui/icons-material/NearMe";
import SportsSharpIcon from "@mui/icons-material/SportsSharp";
import { CiStar } from "react-icons/ci";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import { Card,Select } from "antd";
import Navbar from "../../component/common/NavbarProfile";

import image from "../../assets/images/cardimage.png";
import defaultPitch from "../../assets/images/pitch default.jpeg";
import { venueListingPage } from "../../handlers/common/api";
import { getActivity } from "../../handlers/Search";

const Venue = () => {
  const [venueData, setVenueData] = useState([]);
  const [venueImages, setVenueImages] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activity, setActivity] = useState("");
  const [selectedVenue, setSelectedVenue] = useState(null);

  useEffect(() => {
    fetchVenueData();
  }, []);

  const handleVenueSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleVenueClick = (venue) => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${venue.address}`);
  };

  const handleActivityChange = (event) => {
    setActivity(event.target.value);
  };
  const handleCart = () => {
    localStorage.removeItem("cartItems")
  }

  const handleSearch = () => {
    if (!venueData.data) return;

    const filteredData = venueData.data.filter((venue) => {
      if (!venue || !venue.name || !venue.activity) return false;

      return (
        venue.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        venue.activity.toLowerCase().includes(activity.toLowerCase())
      );
    });
    setVenueData({ ...venueData, data: filteredData });
  };

  const fetchVenueData = async () => {
    try {
      const response = await fetch(
        `${venueListingPage}?fields=["name","address","venue_name","base_url","venue_image"]&limit_page_length=1000`
      );
      const datas = await response.json();
      // console.log(datas,"data")
      setVenueData(datas);
      setVenueImages(
        datas.map((venue) => `${venue.base_url}${venue.venue_image}`)
      );
    } catch (error) {
      console.error("Error fetching venueData:", error);
    }
  };

  const SearchActivity = async() =>{
    const res = await getActivity();
    if(res.status === 200){
      console.log(res.data,"Data")
    }
  };
  
  useEffect(()=>{
    SearchActivity();
  }, [])

  console.log(venueData, "Setvalue");

  const VenueCard = ({ venue }) => {
    const [showFullTitle, setShowFullTitle] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const cardHeight = 314;

    const toggleFavorite = () => {
      setIsFavorite(!isFavorite);
    };

    return (
      <div className="relative flex">
        <Card
          hoverable
          className="venue-card flex flex-col justify-center rounded-lg w-80 m-4"
          style={{
            height: cardHeight,
            overflow: "hidden",
            boxShadow: "0px 4px 10px 0px #0000001A",
          }}
          cover={
            <Link to={`/venue-listing-page/venue/${venue.name}`} onClick={() => handleCart(venue)}>
              <div style={{ position: "relative" }}>
                <img
                  src={`${venue.base_url}${venue.venue_image}`}
                  alt={venue.name}
                  className="w-full h-42 object-cover rounded-t-lg"
                  onError={(e) => {
                    e.target.src = defaultPitch;
                  }}
                />
                <div className="absolute bg-[#267E3E] items-center w-15 flex bottom-1 left-1 text-sm text-white rounded-md p-1">
                  4.4
                  <CiStar />
                </div>
              </div>
            </Link>
          }
        >
          <div className="absolute top-0 right-0 mt-0 mr-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="26.13"
              height="22.79"
              className={`heart-icon cursor-pointer transition-colors duration-300 ${
                isFavorite ? "text-red-500" : "text-white"
              }`}
              onClick={toggleFavorite}
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
              />
            </svg>
          </div>
          <div className="flex flex-col flex-grow ml-3">
            <div
              className="text font-bold whitespace-pre-line"
              style={{ maxHeight: 80, overflow: "hidden" }}
            >
              {venue.name}
            </div>
            <p className="text-gray-600">Address: {venue.address}</p>
          </div>
        </Card>
      </div>
    );
  };

  return (
    <>
      <Navbar />

      <div className="flex justify-between items-start max-w-7xl mx-auto searchbarFilter">
        <div className="flex  mt-4 nearMeSports">
          <div className="flex flex-col items-center relative">
            <NearMeIcon />
            <div className="font-bold cursor-pointer" onClick={() => setSelectedVenue(selectedVenue === null ? venueData.data : null)}>
              Venue
              </div>
              {selectedVenue && (
              <div className="card-index bg-white border rounded-md p-2 mt-2">
                {venueData.data?.map((venue) => (
                  <div key={venue.name} onClick={() => handleVenueClick(venue)} className="cursor-pointer hover:bg-gray-100 p-1">
                    {venue.name}
                  </div>
                ))}
              </div>
            )}
          </div>
          </div>
        <div className="flex justify-center searchBar">
          <div className="flex justify-between items-center w-full border text-center rounded-full mt-3 border-gray-300 p-2 searchBarContent">
            <div className="flex items-center pr-8 border-r border-gray-300 venueSearch">
              <div className="text-center font-bold div-venue">
                Where <br />
                <input
                  type="text"
                  placeholder="Search Venue"
                  className="mx-2  text-center"
                  onChange={handleVenueSearchChange}
                />
              </div>
            </div>
            <div className="flex items-center pl-8 border-r pr-8 activitySearch">
              <div className="font-bold div-activity">
                Search Sport <br />
                <input
                  type="text"
                  placeholder="Choose Activity"
                  className="mx-2  text-center"
                  onChange={handleActivityChange}
                />
              </div>
            </div>
            <div className="flex items-center  text-center pl-8 mr-2 searchButton">
              <div className="font-bold div-search">
                Search 
                <SearchIcon />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex ml-4">
          <div className="flex justify-between items-center ml-5 w-full border text-center rounded-full mt-5 border-gray-300 p-4 lg:p-3">
            <div className="flex justify-between mr-2">
              <div className="font-bold ">
                Filters
                <TuneIcon className="ml-2" />
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div className="flex flex-wrap  justify-center  mb-10">
        {venueData.data?.map((venue) => (
          <VenueCard key={venue.name} venue={venue} />
        ))}
      </div>
    </>
  );
};

export default Venue;
