import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, Navigate } from "react-router-dom";

import { signIn } from "../../handlers/authentication/api";

const LogInPopup = () => {
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isPasswordValid = password.length >= 6;

    setIsFormValid(isEmailValid && isPasswordValid);
  }, [email, password]);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setError("Please enter a valid email and password");
      return;
    }

    try {
      const data = await signIn(email, password);
      if (data.token) {
        localStorage.setItem("token", data.token);
        setIsLoggedIn(true);
        toast.success("Successfully Log In");
      } else {
        throw new Error("User Not Found");
      }
    } catch (error) {
      console.error("Error signing in:", error.message);
      toast.warning(error.message);
    }
  };

  if (isLoggedIn) {
    localStorage.removeItem("role_name");
    return <Navigate to="/viewcart" />;
  }

  return (
    <div
      className="fixed shadow-md items-center justify-center text-center"
      style={{
        width: "450px",
        height: "600px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "50",
        backgroundColor: "#EDD7D7",
      }}
    >
      <h2 class="text-2xl font-bold leading-tight text-black mt-14">
        Login or Create Account to <br />
        checkout
      </h2>
      <p class="mt-3 text-sm text-gray-600 ">
        Create an account to book an amazing experience.
      </p>
      <div class="mt-5 space-y-3 ">
        <button
          type="button"
          class="relative inline-flex w-80 items-center justify-center rounded-md border border-gray-500 bg-white px-3.5 py-2.5 font-semibold text-gray-700 transition-all duration-200 hover:bg-gray-100 hover:text-black focus:bg-gray-100 focus:text-black focus:outline-none"
        >
          <span class="mr-2 inline-block">
            <svg
              class="h-6 w-6 text-rose-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M20.283 10.356h-8.327v3.451h4.792c-.446 2.193-2.313 3.453-4.792 3.453a5.27 5.27 0 0 1-5.279-5.28 5.27 5.27 0 0 1 5.279-5.279c1.259 0 2.397.447 3.29 1.178l2.6-2.599c-1.584-1.381-3.615-2.233-5.89-2.233a8.908 8.908 0 0 0-8.934 8.934 8.907 8.907 0 0 0 8.934 8.934c4.467 0 8.529-3.249 8.529-8.934 0-.528-.081-1.097-.202-1.625z"></path>
            </svg>
          </span>
          Sign in with Google
        </button>
      </div>
      <div className="mt-4"> or</div>

      <form onSubmit={handleLogin} className="mt-8">
        <div class="space-y-5">
          <div>
            <div class="mt-2">
              <input
                class=" h-10 w-80 rounded-md border border-gray-500 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                type="email"
                id="email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
                placeholder="Email"
                required
              />
            </div>
          </div>
          <div>
            <div class="mt-2">
              <input
                class=" h-10 w-80 rounded-md border border-gray-500 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              disabled={!isFormValid}
              class={`inline-flex w-80 items-center justify-center rounded-md bg-black px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-black/80 ${
                !isFormValid && "opacity-50 cursor-not-allowed"
              }`}
            >
              Login{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="ml-2"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </button>
          </div>
          {error && <p className="text-red-500">{error}</p>}
        </div>
      </form>
      <div class=" items-center justify-end mt-4">
        <Link
          to="/sign-in/forgot-password"
          class="text-sm font-semibold text-black underline"
        >
          {" "}
          Forgot password?{" "}
        </Link>
      </div>
      <p class="mt-2 text-sm text-gray-600 ">
        Don&#x27;t have an account?{" "}
        <Link to="/sign-up" className="underline inline-block text-black">
          Create Account
        </Link>
      </p>
    </div>
  );
};

export default LogInPopup;
