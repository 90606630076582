import axios from "axios";
import profileIcon from "../../assets/images/defaultProfilePic.jpg";
import {
  UpdateCard,
  UserGetDetails,
  UpdateProfilePic,
  SendOTP,
  VerifyOtp,
  SetNewPhoneNumber,
  SendNotification,
  UpdatePhoneNumber,
  BookingHistory,
} from "../common/api";

export const fetchUserDatasApi = async (token) => {
  try {
    const response = await axios.get(UserGetDetails, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });

    if (response.status === 200) {
      const userData = response.data.data;

      if (userData) {
        const email = userData.email || "";
        const phone = userData.mobile || "";
        const image = userData.profile_pic || profileIcon;

        return {
          email: email,
          phone: phone,
          image: image,
        };
      } else {
        return {
          email: "",
          phone: "",
          image: profileIcon,
        };
      }
    } else {
      throw new Error("Failed to fetch user data");
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return {
      email: "",
      phone: "",
      image: profileIcon,
    };
  }
};

export const uploadProfilePicApi = async (file, token) => {
  try {
    const formData = new FormData();
    formData.append("img", file);

    const response = await axios.post(UpdateProfilePic, formData, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    console.log(response);
    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to upload image");
    }
  } catch (error) {
    throw new Error("Error uploading image:", error);
  }
};

export const requestSignupOTPApi = async (newPhoneNumber, token) => {
  try {
    const otpResponse = await axios.post(
      SendOTP,
      {
        phone: newPhoneNumber,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${token}`,
        },
      }
    );

    if (otpResponse.status === 200) {
      return true;
    } else {
      throw new Error("Failed to send OTP");
    }
  } catch (error) {
    throw new Error("Error sending OTP:", error);
  }
};

export const changeUserPhoneNumber = async (newPhoneNumber, token) => {
  try {
    const response = await axios.post(
      UpdatePhoneNumber,
      {
        phone: newPhoneNumber,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return true;
    } else {
      throw new Error("Failed to send OTP");
    }
  } catch (error) {
    throw new Error("Error sending OTP:", error);
  }
};

export const verifySignupOTPApi = async (otp, newPhoneNumber, token) => {
  try {
    const response = await axios.post(
      VerifyOtp,
      {
        otp: otp,
        phone: newPhoneNumber,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return true;
    } else {
      throw new Error("Failed to verify OTP");
    }
  } catch (error) {
    throw new Error("Error verifying OTP:", error);
  }
};

export const setNewMobileApi = async (newPhoneNumber, token) => {
  try {
    const response = await axios.post(
      SetNewPhoneNumber,
      {
        phone: newPhoneNumber,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return true;
    } else {
      throw new Error("Failed to update phone number after OTP verification");
    }
  } catch (error) {
    throw new Error("Error updating phone number:", error);
  }
};

export const getBookingHistory = async (token) => {
  try {
    const response = await axios.get(
      BookingHistory,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Error getting booking history:", error);
  }
};

export const fetchUserDataApi = async (token) => {
  try {
    const response = await axios.get(UserGetDetails, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to fetch user data");
    }
  } catch (error) {
    throw new Error("Error fetching user data:", error);
  }
};

export const sendNotificationSettingsApi = async (payload, token) => {
  try {
    const response = await axios.post(SendNotification, payload, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to update notification settings");
    }
  } catch (error) {
    throw new Error("Error updating notification settings:", error);
  }
};

export const getUserDetailsApi = async (token) => {
  try {
    const response = await axios.get(UserGetDetails, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data.data.saved_cards;
    } else {
      throw new Error("Failed to fetch user details");
    }
  } catch (error) {
    throw new Error("Error fetching user details:", error);
  }
};

export const updateCardDetailsApi = async (formattedData, token) => {
  try {
    const response = await axios.post(UpdateCard, formattedData, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to update card details");
    }
  } catch (error) {
    throw new Error("Error submitting data:", error);
  }
};

export const deleteCardApi = async (updatedCards, token) => {
  try {
    const formattedData = {
      card_details: updatedCards.map((card) => ({
        card_number: card.card_number,
        preferred_payment_method: card.preferred_payment_method,
      })),
    };

    const response = await axios.post(UpdateCard, formattedData, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to delete card");
    }
  } catch (error) {
    throw new Error("Error deleting card:", error);
  }
};
