import React, { useEffect, useState } from "react";
import Navbar from "../../component/common/NavbarProfile";
import Success from "../../assets/images/SuccessFull.png";
import sha512 from "crypto-js/sha512";
import encHex from "crypto-js/enc-hex";
import { toast } from "react-toastify";
import { handlePayment } from "../../handlers/common/api";
import {
  CheckIfPaymentAlreadyDone,
  Create_si_and_payment_api,
  handleRedirect,
} from "../../handlers/Payment/requestpayment";

const SuccessfullPage = () => {
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [venueData, setVenueData] = useState([]);

  const [callonce, setcallonce] = useState(true);

  useEffect(() => {
    const venue_data = JSON.parse(localStorage.getItem("venueData")) || {};
    setVenueData(venue_data);
  }, []);

  const create_si = async (firstPart) => {
    const token = localStorage.getItem("token");

    const venue_data = JSON.parse(localStorage.getItem("venueData")) || {};
    setVenueData(venue_data);
    const SI_Payload = {
      si_details: {
        order_number: firstPart,
        booking_for: venue_data?.booking_for,
        venue: venue_data?.data?.name,
        payment_gateway_response: "Success",
      },
    };

    try {
      if (callonce) {
        console.log(callonce);
        const response = await Create_si_and_payment_api(SI_Payload, token);

        if (response) {
          toast.success("Order has been Created!");
          console.log(response);
          setcallonce(false);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to create order111111");
    }
  };

  const fetchTransactionDetails = async () => {
    const token = localStorage.getItem("token");
    var transaction_id = "";
    if (typeof window !== "undefined" && window.location.search) {
      var searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("transaction_id")) {
        transaction_id = searchParams.get("transaction_id");
        let splitValues = transaction_id.split("_");
        let firstPart = splitValues[0];

        const payload = {
          request: {
            transaction_id: transaction_id,
          },
        };
        const res = await handleRedirect(payload);
        if (
          res.data.message ===
          "signature is fine, continue processing the request"
        ) {
          setIsLoading(false);
          setShowPaymentSuccess(true);
          const res2 = await CheckIfPaymentAlreadyDone(firstPart, token);
          if (res2.data.data.payment_status === 0) {
            create_si(firstPart);
          }
        }
      }
    } else {
      setIsLoading(false);
      return;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      localStorage.removeItem("role_name");
    }
    fetchTransactionDetails();
  }, []);

  return (
    <>
      <Navbar />
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="loader"></div>
        </div>
      ) : (
        showPaymentSuccess && (
          <div className="payment-successfull-page">
            <div className="text-center mt-14">
              <h1>Payment Successful</h1>
              <p>Booking done Successfully!</p>
            </div>
            <div className="relative isolate  bg-white">
              <div className="relative  py-24">
                <div className="text-center">
                  <div className="mt-10 flex flex-col items-center justify-center border border-gray-300 absolute inset-0 mx-auto payment-successfull-box">
                    <div className="rounded-full mt-5  bg-[#F6E0E1] flex justify-center items-center">
                      <img
                        src={Success}
                        width={90}
                        height={20}
                        className=""
                        alt="Success"
                      />
                    </div>
                    <h3 className="mt-4"> Done!</h3>
                    <span className="mt-2 mb-8">
                      Booking done Successfully!{" "}
                    </span>
                    <button
                      type="button"
                      className="px-3 py-2 text-sm font-semibold text-white download-invoice"
                    >
                      Download Invoice
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default SuccessfullPage;
