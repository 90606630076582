import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import Navbar from "../../component/common/NavbarProfile";
import "./index.css";
import "../index.css";
import { isNumber } from "util";
import { handleCreateOrder } from "../../handlers/product/api";
import { toast } from "react-toastify";
import image from "../../assets/images/cardimage.png";
import { Card, Input } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "antd";
import { requestPayment } from "../../handlers/Payment/requestpayment";
import Defaultimage from "../../assets/images/CricketKit.jpg";
import ReddotPyament from "../PaymentScreen/RequestUrlPayment";
const CartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [product_Service, setProduct_Service] = useState();
  const [venueData, setVenueData] = useState([]);
  const { Meta } = Card;
  const [orderDetails, setOrderDetails] = useState([]);
  const [paymentURL, setPaymentURL] = useState([]);

  // const Navigate = Navigate();

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(items);
    const product_Service =
      JSON.parse(localStorage.getItem("Product_Service")) || {};
    setProduct_Service(product_Service);
    const venue_data = JSON.parse(localStorage.getItem("venueData")) || {};
    setVenueData(venue_data);
  }, []);

  const processData = (data) => {
    // Filter items with item_category "Services"
    const servicesData = data.filter(
      (item) => item.item_category === "Services"
    );

    // Group items by time and date
    const groupedData = servicesData.reduce((acc, item) => {
      const { time, date } = item;
      const key = `${date}_${time}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    // Combine pitches with the same time and date
    const combinedData = Object.entries(groupedData).map(([key, items]) => {
      const [date, time] = key.split("_");
      const pitches = items.map((item) => item.pitch);
      const sortedPitches = Array.from(new Set(pitches)).sort();
      const combinedPitches = sortedPitches.join(", ");
      return {
        time,
        name: `${items[0].name.replace(
          /\|([^|]+)\|([^|]+)$/,
          `| ${combinedPitches} |$2`
        )}`,
        item_category: "Services",
        pitch: combinedPitches,
        date,
        // Add other properties if needed
      };
    });

    return combinedData;
  };

  const ungroupNames = (name) => {
    const regex = /\| ([^|]+) \|/;
    const matches = name.match(regex);
    if (matches && matches.length > 1) {
      const pitches = matches[1].split(", ");
      return pitches.map(
        (pitch) => `${name.replace(matches[0], `| ${pitch} |`)}`
      );
    }
    return [name];
  };

  const removeFromCart = (itemToRemove) => {
    const updatedCartItems = cartItems.filter(
      (item) =>
        !ungroupNames(itemToRemove.name).includes(item.name) ||
        item.time !== itemToRemove.time ||
        item.date !== itemToRemove.date
    );

    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
  };

  const numberofPitchesInCart = () => {
    const updatedCartItems = cartItems.filter(
      (item) => item.item_category === "Services"
    );
    return updatedCartItems.length;
  };

  function convertTo24hrDateTime(dateString, timeRangeString) {
    // Parse the date string
    const dateParts = dateString.split(" ");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames.indexOf(dateParts[1]) + 1;
    const day = parseInt(dateParts[0], 10); // Parse the day as an integer
    const year = parseInt(dateParts[2], 10); // Parse the year as an integer

    // Parse the time range string
    const [startTime, endTime] = timeRangeString.split("-");

    // Convert start time to 24-hour format
    const [startHour, startMinute] = startTime
      .split(":")
      .map((part) => parseInt(part, 10));
    const startDate = new Date(
      year,
      month - 1,
      day,
      (startHour % 12) + (startTime.includes("PM") ? 12 : 0),
      startMinute
    );
    const startDay = startDate.getDate().toString().padStart(2, "0"); // Ensure day has leading zero if necessary
    const startMonth = (startDate.getMonth() + 1).toString().padStart(2, "0"); // Ensure month has leading zero if necessary
    const startYear = startDate.getFullYear();
    const startDateTimeString = `${startYear}-${startMonth}-${startDay} ${
      startDate.toTimeString().split(" ")[0]
    }`;

    // Convert end time to 24-hour format
    const [endHour, endMinute] = endTime
      .split(":")
      .map((part) => parseInt(part, 10));
    const endDate = new Date(
      year,
      month - 1,
      day,
      (endHour % 12) + (endTime.includes("PM") ? 12 : 0),
      endMinute
    );
    const endDay = endDate.getDate().toString().padStart(2, "0"); // Ensure day has leading zero if necessary
    const endMonth = (endDate.getMonth() + 1).toString().padStart(2, "0"); // Ensure month has leading zero if necessary
    const endYear = endDate.getFullYear();
    const endDateTimeString = `${endYear}-${endMonth}-${endDay} ${
      endDate.toTimeString().split(" ")[0]
    }`;

    return [startDateTimeString, endDateTimeString];
  }

  const createOrder = async () => {
    const token = localStorage.getItem("token");

    let payload = {
      booking_for: venueData.booking_for,
      venue: venueData.data.name,
      pitches_slots: cartItems
        .filter((item) => item.item_category === "Services")
        .map((pitch) => ({
          pitch_name: pitch.pitch,
          item_code: product_Service.item_code,
          item_category: "Services",
          rate: product_Service.rate,
          booking_state: "In Progress",
          slot_start_date_time: convertTo24hrDateTime(
            pitch.date,
            pitch.time
          )[0],
          slot_end_date_time: convertTo24hrDateTime(pitch.date, pitch.time)[1],
        })),
      consumable_products: cartItems
        .filter((item) => item.item_category === "Products")
        .map((item) => ({
          item_code: item.name,
          qty: item.quantity * processData(cartItems).length,
          rate: item.price,
          item_category: "Products",
        })),
      rental_products: cartItems
        .filter((item) => item.item_category === "Rental")
        .map((item) => ({
          item_code: item.name,
          qty: item.quantity,
          rate: item.price,
          hrs: 1 * numberofPitchesInCart(),
          item_category: "Rental",
        })),
    };

    try {
      const response = await handleCreateOrder(payload, token);

      if (response) {
        toast.success("Order has been Created!");
        const orderDetails = {
          order: {
            order_id: response.data.data.ref_number.so_no,
            amount: response.data.data.ref_number.amount,
            // card_no: "4111111111111111",
            // cvv2: "111",
            // exp_date: "102025",
          },
        };

        const res = await requestPayment(orderDetails);
        if (res.status === 200) {
          setPaymentURL(res.data.message);
          window.location.href = res.data.message.payment_url;
        }
        setOrderDetails(response.data.data.ref_number);
        console.log(response.data.data.ref_number, "data");
      } else {
        toast.warning("Failed to create order");
      }
    } catch (error) {
      toast.error("Failed to create order");
    }
  };

  // const RequestPaymentURL = async () => {
  //   const orderDetails = {
  //     order: {
  //       order_id: "T1",
  //       amount: "3.0",
  //     },
  //   };
  //   try {
  //     const res = await requestPayment(orderDetails);
  //     if (res.status === 200) {
  //       setPaymentURL(res.data.message);
  //       window.location.href = res.data.message.payment_url;
  //     }
  //   } catch (error) {}
  // };

  function calculateHourDifference(timerange) {
    // Split the timerange into start and end times
    const [startTime, endTime] = timerange
      .split("-")
      .map((time) => time.trim());

    // Function to convert time to minutes
    const timeToMinutes = (time) => {
      const [hourStr, minuteStr, meridiem] = time.split(/:| /);
      let hour = parseInt(hourStr, 10);
      const minute = parseInt(minuteStr, 10);
      // Adjust hour for PM time
      if (meridiem === "PM" && hour !== 12) {
        hour += 12;
      }
      return hour * 60 + minute;
    };

    // Convert start time to minutes
    const startMinutes = timeToMinutes(startTime);

    // Convert end time to minutes
    const endMinutes = timeToMinutes(endTime || startTime); // Use start time if end time is undefined

    // Calculate the difference in minutes
    let hourDifference = Math.abs((endMinutes - startMinutes) / 60);

    return hourDifference;
  }

  const calculateTotal = () => {
    let total_hours = cartItems
      .filter((item) => item.item_category === "Services")
      .reduce((acc, item) => {
        console.log(item);
        // Check if item price is a valid number
        const price =
          typeof item.price === "number" && !isNaN(item.price) ? item.price : 0;
        // Add the valid price to the accumulator
        const quantity =
          typeof item.quantity === "number" && !isNaN(item.quantity)
            ? item.quantity
            : 1;
        const hours = calculateHourDifference(item.time);
        console.log(hours);
        // Add the valid price to the accumulator
        return acc + hours;
      }, 0);

    console.log(total_hours);
    let partA = cartItems
      .filter((item) => item.item_category !== "Services")
      .reduce((acc, item) => {
        // Check if item price is a valid number
        const price =
          typeof item.price === "number" && !isNaN(item.price) ? item.price : 0;
        // Add the valid price to the accumulator
        const quantity =
          typeof item.quantity === "number" && !isNaN(item.quantity)
            ? item.quantity
            : 1;
        // Add the valid price to the accumulator
        console.log("11111", price, quantity, total_hours);

        return acc + price * quantity * total_hours;
      }, 0);

    console.log(partA);
    console.log(partA);
    let partB = cartItems
      .filter((item) => item.item_category === "Services")
      .reduce((acc, item) => {
        // Check if item price is a valid number
        const price = parseInt(product_Service.rate);
        // Add the valid price to the accumulator
        const quantity =
          typeof item.quantity === "number" && !isNaN(item.quantity)
            ? item.quantity
            : 1;
        // Add the valid price to the accumulator
        console.log(acc + price * quantity, price, quantity, total_hours);
        return acc + price * quantity;
      }, 0);

    console.log(partB);
    return partA + partB;
  };

  const calculateTotalWithGST = () => {
    const subtotal = calculateTotal();
    const gstRate = 9;
    const gstAmount = (subtotal * gstRate) / 100;
    return gstAmount;
  };

  const totalAmt = () => {
    const subtotal = calculateTotal();

    const gstAmount = calculateTotalWithGST();
    return subtotal + gstAmount;
  };

  return (
    <>
      <Navbar />
      <div className="cart-container flex">
        {/* <h2>Your Shopping Cart</h2> */}
        <div className="col-lg-8 cart-item-wrapper">
          {cartItems.length === 0 ? (
            <p className="font-bold border border-red-300 h-12 w-full text-center mt-7 ">
              Your cart is empty.
            </p>
          ) : (
            <>
              {console.log(cartItems)}
              {console.log(processData(cartItems))}
              {processData(cartItems).map((item, index) => {
                return (
                  <div key={index} className="cart-item">
                    <div className="cart-item-img">
                      <img
                        src={image}
                        alt={item.name}
                        onError={(e) => {
                          e.target.src = Defaultimage;
                        }}
                      />
                    </div>
                    <div className="cart-item-detail">
                      <h5>{item.name}</h5>
                      <p className="cart-item-time">{item.time}</p>
                      <p className="cart-item-price">
                        ${product_Service.rate}/hr
                      </p>
                      <button onClick={() => removeFromCart(item)}>
                        Remove
                      </button>
                    </div>
                    <div className="cart-item-date">
                      Scheduled on {item.date}
                    </div>
                  </div>
                );
              })}
              {cartItems.map((item, index) => {
                return (
                  item.item_category !== "Services" && (
                    <div key={index} className="cart-item">
                      <div className="cart-item-img">
                        <img
                          src={item.image}
                          alt={item.name}
                          onError={(e) => {
                            e.target.src = Defaultimage;
                          }}
                        />
                      </div>
                      <div className="cart-item-detail">
                        <h5>{item.name}</h5>
                        <p className="cart-item-qty">
                          Quantity: {item.quantity}
                        </p>
                        <p className="cart-item-price">
                          Price: ${item.price}/hr
                        </p>
                        <button onClick={() => removeFromCart(item)}>
                          Remove
                        </button>
                      </div>
                      <div className="cart-item-date">
                        Scheduled on {item.date}
                      </div>
                    </div>
                  )
                );
              })}
              <div className="total">
                <h3>Total: ${calculateTotal()}</h3>
              </div>
              {/* <div className="flex justify-end mr-25">
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-20 mb-7"
                  onClick={createOrder}
                >
                  Proceed to Checkout
                </button>
              </div> */}
            </>
          )}
        </div>
        <div className="col-lg-4">
          <div className="price-details">
            <div>
              <h5 className="pl-3 pt-3">ORDER SUMMARY</h5>
              <hr></hr>
            </div>
            <div className="price-content">
              <p className="promo-code">
                <input placeholder=" " className="Coupon-code"></input>
                <label className="promo-label">Enter Coupon Code</label>
              </p>
              <div className="price-cal">
                <div className="flex justify-content-between">
                  <p>SubTotal:</p>
                  <p>${calculateTotal()}</p>
                </div>
                {/* <div className="flex justify-content-between">
                  <p className="Gst">GST 9%:</p>
                  <p className="Gst">$18</p>
                </div> */}
                <hr></hr>
                <div className="flex justify-content-between">
                  <p className="total">Total</p>
                  <p className="total">${calculateTotal()}</p>
                </div>
              </div>
              <></>
            </div>
          </div>
          <div className="mt-3 pl-8 payment_button">
            <Button className="paymentButton" onClick={createOrder}>
              Proceed with Payment
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartPage;
